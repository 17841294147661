.illustration {
  display: flex;
  max-width: 650px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1000px) {
    display: none;
  }

  &__block {
    display: flex;
    max-width: 300px;
    width: 100%;
    padding: 60px 20px 0px 20px;

    @media (max-width: 1200px) {
      padding-right: 0;
    }

    img {
      z-index: 1;
    }
  }
}
