.firstContainer {
  display: flex;
  margin-bottom: 50px;
  margin-top: 50px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.views {
  path {
    fill: #541690;
  }
  g {
    stroke: #541690;
  }
}

.videoStatisticContainer {
  display: grid;
  width: 50%;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  margin-right: 30px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: unset;
  }
}
.sortContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
}
.countrySelect {
  width: 100%;
  max-width: 375px;
}
.piesContainer {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 0.34fr 0.34fr 0.34fr;
  grid-gap: 13px;
  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
.statItem {
  background-color: white;
  border-radius: 10px;
  padding: 27px 0 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rangeContainer {
  @media screen and (max-width: 1500px) {
    max-width: 55%;
  }
  @media screen and (max-width: 1200px) {
    max-width: 100%;
    margin-top: 20px;
  }
}
.rangeButton + .rangeButton {
  margin-left: 10px;
}
.dayButtons {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
.rangeInner {
  padding: 10px;
  height: unset;
  font-size: 14px;
  @media screen and (max-width: 1300px) {
    font-size: 11px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.periodText {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
  @media screen and (max-width: 1300px) {
    font-size: 11px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.desktop {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.mobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
}
