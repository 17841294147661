.container {
    background-color: white;
    border-radius: 10px;
    padding: 27px 5px 39px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
}
.icon{
    width: 32px;
    height: 32px;
    color: #541690;
}

.label{
    color: #541690;
    font-size: 16px;
    margin-top: 14px;
    margin-bottom: 14px;
    text-align: center;
}

.stat{
    color: #1d1d1f;
    font-size: 16px;

}