@import "src/styles/colors";

.data-table {
  border-radius: 10px;
  position: relative;
  width: 100%;
  transition: all 0.3s linear !important;
  padding: 0 0 10px !important;
  scrollbar-gutter: stable;

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  &::-webkit-scrollbar {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: block;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {

    background-color: white;
    height: 500px;
    max-height: 500px;
    border-style: solid;
    border-color: transparent;
    border-left-width: 40px;
    border-right-width: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #ff5f15, #ff9a15);
    visibility: hidden;
    width: 8px;
    min-height: 150px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    border-radius: 4px;
    background-color: white;
  }

  & &__table {
    margin: auto;
    tbody {
      background-color: white;
      tr:first-child {
        td {
          padding-top: 19px !important;
        }
      }
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 10px;
        }
        td:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  .MuiTablePagination-spacer {
    display: none;
  }
}
.arrow-up {
  transform: translateX(-50%) rotate(180deg) !important;
}
.with-expensive {
  table {
    tbody {
      tr {
        td {
          height: 89px;
        }
      }
    }
  }
}
