.overlay {
  z-index: 10000;

  :global(.MuiBackdrop-root) {
    background-color: rgb(0, 0, 0);
    opacity: 0.3 !important;
  }
}

.paper {
  width: 320px !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 1rem !important;

  * {
    font-family: "Inter", sans-serif !important;
  }

  @media screen and (max-width: 480px) {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

.items {
  overflow: auto;
  max-height: 360px;
}

.item {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    padding-top: 1rem;
    border-bottom: 0;
  }
}
