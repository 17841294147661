@import "src/styles/colors";
.date-range-content {
  .rdrCalendarWrapper {
    color: $black;
    font-size: 12px;
    font-family: "Mont Blanc", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    border-radius: 10px;
    max-width: 100%;

    @media screen and (max-width: 1200px) {
      margin-top: 20px;
      max-width: 100%;
      width: 100%;
    }
  }
  .rdrMonth {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .rdrStartEdge {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid $primary;
  }

  .rdrEndEdge {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid $primary;
  }

  .rdrInRange {
    color: black;
    background-color: #e2e2ea !important;
  }

  .rdrSelected {
    border-radius: 5px;
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rdrDayToday .rdrDayNumber span:after {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: $primary;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: $black;
  }

  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    background: rgba(255, 255, 255, 0.09);
    border: 0 solid $primary;
  }

  .rdrDayStartPreview {
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    left: 0;
  }

  .rdrDayInPreview {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .rdrDayEndPreview {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    right: 0;
  }
}
