@import "src/theme";

.typography {
  &--variant-h1 {
    font-size: rem(36px);
  }

  &--variant-h2 {
    font-size: 1.75rem !important;
  }

  &--variant-h3 {
    font-size: 1.5rem !important;
  }

  &--variant-h4 {
    font-size: 1.25rem;
  }

  &--variant-h5 {
    font-size: rem(18px);
  }

  &--variant-primary {
    //
  }

  &--variant-secondary {
    //
  }

  &--variant-body1 {
    font-size: 1rem;
  }

  &--variant-body2 {
    font-size: rem(14px);
  }

  &--variant-caption1 {
    font-size: 0.9rem;
  }

  &--variant-caption2 {
    font-size: 0.75rem;
  }

  &--variant-overline {
    text-transform: uppercase;
  }

  &--color-primary {
    color: $text-primary !important;
  }

  &--color-warning {
    color: $color-warning;
  }

  &--color-error {
    color: red !important;
  }

  &--color-black {
    color: black;
  }

  &--color-muted {
    color: $text-muted;
  }

  &--color-white {
    color: $color-white;
  }

  &--color-gray {
    color: #707070;
  }

  &--align-center {
    text-align: center;
  }

  &--align-left {
    text-align: left;
  }

  &--align-right {
    text-align: right;
  }

  &--bold,
  &--weight-bold {
    font-weight: bold;
  }

  &--weight-semi-bold {
    font-weight: 600;
  }

  &--weight-normal-bold {
    font-weight: 500;
  }

  &--inline {
    display: inline-block;
  }

  &--nowrap {
    white-space: nowrap;
  }
}
