@import "../../styles/colors";

.container {
  padding: 40px 25px 30px 14px;
  height: 100%;
}

.pageTitle {
  margin: 0 0 60px 8px !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  color: $primary;

  @media screen and (max-width: 1360px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media screen and (max-width: 768px){
    margin-bottom: 30px !important;
  }
}
.permissions{
  text-align: center;
  font-weight: 600;
}
.buttonsRow {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 23px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px){
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $primary;
    }
  }
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
}

.button {
  width: 48%;
  background-color: $primary;
  padding: 14px 0;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  font-weight: 600;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.button + .button{
  @media screen and (max-width: 768px){
    margin-top: 10px;
  }
}

.auctionModal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modalDialog {
  max-width: 70% !important;
  @media screen and (max-width:768px){
    max-width: 98% !important;
  }
}

.modalContent {
  background-color: #F0F1F7 !important;
  max-height: 90vh;
  overflow: auto;
  padding: 0 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}
.modalBody{
  padding: 0 0 10px !important;
}