@import "src/theme";

.input-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto !important;

    &__label {
        margin-left: 0 !important;
        margin-bottom: 4px;
    }
}

.image-input {
    width: 100%;
    max-width: 100%;

    &__label {
        font-family: "Mont Blanc", sans-serif;
        color: #1D1D1F;
        font-size: 20px;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 4px;

        &--warrning {
            font-family: "Mont Blanc", sans-serif;
            color: #1D1D1F;
            font-size: 20px;
            font-weight: 600;
            margin-left: 10px;
            margin-bottom: 4px;

            &::after {
                content: '*';
                margin-left: 4px;
                color: red;
                font-size: 20px;
            }
        }

        @include down("lg") {
            font-size: 16px;
        }

        @include down("md") {
            font-size: 14px;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 10px;
        transition: border .5s;
        background-color: #FFFFFF;
    }

    &--filled &__wrapper {
        background-color: #FFFFFF;
    }

    &--variant-small &__wrapper {
        height: 60px;
    }

    &--variant-large &__wrapper {
        border: 1px dashed #707070;
        background-color: #FFFFFF;
    }

    &--variant-circle &__wrapper {
        border: 1px dashed #707070;
        background-color: #FFFFFF;
        width: 130px;
        height: 130px;
        border-radius: 50%;

        @media (max-width: 600px) {
            width: 100px;
            height: 100px;
        }
    }

    &--dragging &__wrapper {
        border-color: $color-primary;
    }

    &__empty {
        flex: 1;
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
            p {
                font-size: 12px !important;
            }
        }
    }

    &--variant-small &__empty {
        padding-left: 8px;
        justify-content: space-between;
    }

    &--variant-large &__empty {
        flex-direction: column;
        flex-basis: 170px;
        justify-content: center;

        @media (max-width: 600px) {
            flex-basis: 120px;
        }
    }

    &--variant-circle &__empty {
        flex-direction: column;

        div {
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__preview {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            transform: rotate(-45deg);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            background-color: #FFFFFF;
        }
    }

    &--variant-small &__preview {
        width: 60px;
        height: 60px;
        flex-basis: 60px;
        padding: 8px;

        img {
            object-fit: contain;
            border-radius: 10px;
        }
    }

    &--variant-large &__preview {
        width: 100%;
        height: 170px;
        border-radius: 10px;

        @media (max-width: 600px) {
            height: 120px;
        }

        img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    &--variant-circle &__preview {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        margin-left: -1px;

        @media (max-width: 600px) {
            width: 100px;
            height: 100px;
        }

        img {
            border-radius: 50%;
        }
    }

    &__input {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;

        input {
            position: absolute;
            display: block !important;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    &--variant-small &__input {
        flex-shrink: 0;
    }

    &--variant-large &__input {
        flex-direction: column;
    }

    &--variant-large &__clear {
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
    }

    &__file-name {
        position: relative;
        height: 30px;
        flex: 1;

        span {
            position: absolute;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
            padding: 2px 5px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &--variant-small &__file-name {
        flex-shrink: 0;
        //max-width: calc(100% - 60px - 60px);
        //min-width: 0;
    }

    &--variant-large &__file-name {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 2px;
        background-color: #FFFFFF;
    }

    &__file-icon {
        transform: rotate(-45deg);
    }

    &__left-addons,
    &__right-addons {
        display: flex;
        flex-wrap: nowrap;
        flex-shrink: 0;
    }

    &__right-addons {
        //flex: 0;
        padding-right: 4px;
    }

    &--variant-large &__right-addons {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
    }

    &--variant-circle &__right-addons {
        position: absolute;
        top: 0;
        right: 8px;
        z-index: 2;
        padding-right: 0;
        border-radius: 50%;
        padding: 2px;
        background-color: var(--primary-color);

        @media (max-width: 600px) {
            right: 0px;
        }

        span {
            color: white;
        }
    }

    &__helper {
        margin-top: 4px;
    }

    &__helper-text,
    &__helper-info {
        font-weight: 500;
    }

    &--error &__helper-text {
        color: $text-error;
    }
}