@import "src/styles/colors";

.container{
  width: 800px;
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}
.buttons{
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.button{
  margin: 0 5px;
  width: unset;
}
.ageLabel {
  font-family: "Mont Blanc", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-left: 18px;
  margin-bottom: 4px;

  @media screen and (max-width: 1360px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1240px) {
    font-size: 14px !important;
  }
}
.selectContainer{
  width: 100%;
}


.successContainer {
  height: 700px;
  width: 800px;
  max-height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 150px;
  height: 134px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.text {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-top: 30px;
}
.secondText{
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.done {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  color: $green;
  width: 50px !important;
  height: 50px !important;
}

