@import "src/theme";


.button {
    display: inline-flex;
    align-items: center;
    color: $color-black  !important;

    &--outline {
        color: var(--primary-color);
        position: relative;
        text-align: start;
        transition: opacity 0.2 linear;
        margin-bottom: 15px;
        cursor: pointer;
        width: fit-content;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            width: 100%;
            background-color: var(--primary-color);
        }

        &:hover {
            opacity: 0.8;
        }

        &--disabled {
            text-decoration: none;
            color: gray !important;
            cursor: default;

            &:hover {
                opacity: 1;
            }

            &::after {
                background-color: gray !important;
            }
        }
    }

    &--danger {
        color: white !important;
        cursor: pointer;
        width: fit-content;
        background-color: red !important;
        padding: 14px 72px;
        border-radius: 12px;
        margin-bottom: 0 !important;
    }

    &--dark {
        color: $color-white  !important;
    }

    &--bold {
        font-weight: bold;
    }

    &--full-width {
        width: 100%;
    }

    &--disabled {
        pointer-events: none;
    }

    &--align-center {
        justify-content: center;
    }

    &--align-left {
        justify-content: flex-start;
    }

    &--align-right {
        justify-content: flex-end;
    }

    &--variant-text {
        padding-right: 0.25rem;
        padding-left: 0.25rem
    }

    &--variant-text#{&}--color-primary {
        color: $text-primary  !important;
    }

    &--variant-contained {
        color: $color-white  !important;
        line-height: inherit;
        border-radius: 12px;
        border: 1px solid #FF5F00;
    }

    &--variant-contained#{&}--color-primary {
        background-color: $color-primary;
    }

    &--variant-contained#{&}--disabled {
        color: $color-muted  !important;
        background-color: #e2e2ea !important;
    }

    &--variant-contained#{&}--dark#{&}--disabled {
        color: $color-muted  !important;
        ;
        background-color: #09090b !important;
    }

    &--variant-contained#{&}--color-secondary {
        color: white !important;
        line-height: inherit;
        background-color: #AAAAAA !important;
    }

    &--variant-contained#{&}--color-secondary#{&}--dark {
        background-color: #09090b !important;
    }

    &--variant-outlined {
        padding: 4px 6px;
        border: 1px solid $color-muted;
        border-radius: 10px;
    }

    &--variant-outlined#{&}--color-primary {
        border: 1px solid $color-primary;
    }

    &--variant-contained#{&}--size-large,
    &--variant-outlined#{&}--size-large {
        padding: 12px 72px;
    }

    &--variant-contained#{&}--size-medium,
    &--variant-outlined#{&}--size-medium {
        padding: 0 30px;
        height: 30px;
    }

    &--variant-contained#{&}--size-small,
    &--variant-outlined#{&}--size-small {
        font-size: 0.875rem;
        padding: 0 20px;
        border-radius: 5px;
        height: 25px;
    }

    &__left-addons,
    &__right-addons {
        flex: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
    }

    &__left-addons {
        padding-right: 4px;
    }

    &--size-large &__left-addons {
        padding-right: 8px;
    }

    &__right-addons {
        padding-left: 4px;
    }

    &--size-large &__right-addons {
        padding-left: 8px;
    }
}