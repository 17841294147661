@import "../../../../../../styles/colors";

.container {
  display: flex;
  flex-direction: column;
}
.videoContainer {
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.videoContainer + .videoContainer {
  margin-top: 20px;
}
.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  margin-bottom: 10px;
}
.description {
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
}
.country {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}
.flag {
  border-radius: 6px;
  width: 40px;
  margin-right: 10px;
}
.status {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}
.statusVer {
  color: #6daa50;
}

.statusNotVerify {
  color: #ee1717;
}

.deleted {
  color: black;
}
.actions {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.managementIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  path {
    fill: black;
  }
}

.deleteIcon {
  cursor: pointer;
  margin-left: 10px;
  color: black;
  width: 24px;
  height: 24px;
}
.activeIcon {
  color: $primary;

  path {
    fill: $primary !important;
  }
}

.managementIcon + .managementIcon {
  margin-left: 10px;
}
.propertyWithDesc {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
}
.action {
  color: black;
  margin: 0 5px;
  width: 40px !important;
  height: unset !important;
}
.sortContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.goToStatContainer {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: $primary;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 10px;
  z-index: 10;
}
.arrow {
  color: white;
  font-size: 40px !important;
}
.date{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
