.preview-block {
    @media screen and (max-width: 900px) {
        display: none;
    }
}

.preview-navigate {
    width: 100%;
    height: 24px;
    display: flex;
    border-radius: 8px;
    border: 1px solid white;
    margin-top: 1rem;
    box-shadow: 0px 0px 18px -11px rgba(0, 0, 0, 0.6);

    div {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #F0F1F7;
        cursor: pointer;
        align-items: center;
        font-size: 14px;
        transition: all 0.2s linear;

        &:hover {
            opacity: 0.9;
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &:not(:last-child) {
            border-right: 1px solid white;
        }
    }

    &__active {
        background-color: white !important;
        height: calc(100% + 1px) !important;
    }
}

.preview-wrapper {
    margin-top: 1rem;
    width: 100%;
    height: 320px;
    border-radius: 8px;
    border: 2px solid white;
    display: flex;
    transition: all 0.2s linear;
    overflow: hidden;

    &__menu {
        width: 110px;
        background-color: white;
        height: 280px;
        margin-top: 18px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-left: -1px;
    }

    &__content {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 25px;
        padding-bottom: 18px;

        &_company {
            display: flex;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: white;
            }

            span {
                margin-left: 10px;
                font-size: 10px;
                font-weight: 500;
            }
        }

        &_video {
            margin-top: 1rem;
            height: 180px;
            border-radius: 8px;
            background-color: white;
        }

        &_info {
            margin-top: 1rem;

            p {
                font-size: 12px;
            }

            span {
                display: block;
                font-size: 10px;
            }
        }
    }
}

.preview_PC {
    .preview-wrapper__menu {
        width: 60px !important;
        height: 300px !important;
        margin-top: 8px !important;
    }

    .preview-wrapper__content {
        padding-right: 15px !important;
        padding-left: 15px !important;
        padding-top: 12px !important;
        padding-bottom: 8px !important;

        &_video {
            margin-top: 0.5rem !important;
            height: 210px !important;
        }
    }
}

.preview_Mobile {
    flex-direction: column !important;
    border-right: 160px solid white !important;
    border-left: 160px solid white !important;

    .preview-wrapper__menu {
        width: calc(100% - 10px) !important;
        height: 30px !important;
        margin: 0 auto;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 8px !important;
        margin-top: 0 !important;
    }

    .preview-wrapper__content {
        padding: 15px !important;

        &_video {
            height: 100px !important;
        }
    }
}