.auth-country-select {
  &__label {
    font-size: 15px;
    font-weight: 500;
    color: #9596a2;
    margin-bottom: 6px;
  }

  &__select {
    width: 100%;
    font-size: 16px;

    &__control {
      border: 1px solid #e2e2ea !important;
      min-height: 50px !important;

      &--is-focused {
        border-color: #819bea !important;
        box-shadow: 0 1px 4px 0 #819bea !important;
      }
    }
    &__single-value,
    &__option {
      display: flex !important;
      align-items: center;

      img {
        margin-right: 0.75rem;
        height: 26px;
        border-radius: 4px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      z-index: 111111 !important;
      padding: 5px 0 !important;

      &-list {
        padding: 0 !important;
        position: relative !important;

        @media (min-width: 1024px) {
          &::-webkit-scrollbar {
            width: 4px;
            position: absolute;
            right: 0;
            background: transparent;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: transparent;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #9596a2;
            border-radius: 3px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #888;
          }
        }
      }
    }

    &__option {
      display: flex !important;

      &--is-focused {
        background: #e2e2ea !important;
      }

      &--is-selected {
        background: var(--primary-color) !important;
        color: white !important;
      }
    }
  }
}
