.wrapper {
    background-color: var(--grey-color);
    height: 100%;
    /* padding-top: 20px; */
}
.info {
    margin-bottom: 15px;
}
.profile-icon {
    width: 65px;
    border-radius: 50%;
    height:65px;
    object-fit: cover;
    margin-right: 15px;
}

/* new */
.videoWrapper {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

.video {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}
.video__player {
    width: 100%;
    height: 100%;
    object-fit: fill;
    scroll-snap-align: start;
}
.video > div:nth-child(1) > div:nth-child(1) {
    display: none !important;
  }

@media screen and (max-width: 600px) {
    .container-padding-none {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
/* .swiper-wrapper {
    padding: 20px;
} */