.tooltip-container {
  position: relative;
  display: inline-block;
  .tooltip-content {
    transition: all 0.2s linear;
    visibility: hidden;
    background-color: black;
    font-size: 12px;
    border-radius: 5px;
    font-weight: 300;
    color: #fff;
    padding: 6px 10px;
    left: calc(100% + 12px);
    width: 280px;
    text-align: center;
    position: absolute;
    z-index: 1111;
    opacity: 0;

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 100%; /* To the left of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }
  }
  &:hover {
    .tooltip-content {
      visibility: visible;
      opacity: 1;
    }
  }
}

.bottom-content {
  top: 100% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: fit-content !important;
  white-space: nowrap;

  &::after {
    bottom: 100% !important;
    left: 50% !important;
    margin-left: -5px !important;
    border-width: 5px !important;
    border-color: transparent transparent black transparent !important;
    top: 0 !important;
    margin-top: -10px !important;
  }
}
