@font-face {
  font-family: "Mont Blanc";
  font-style: normal;
  font-weight: normal;
  src: local("MontBlanc"),
    url("./assets/fonts/MontBlanc/MONT-BLANC-REGULAR.OTF") format("opentype"); /* Modern Browsers */
}
@font-face {
  font-family: "Mont Blanc";
  font-style: normal;
  font-weight: bold;
  src: local("MontBlanc"),
    url("./assets/fonts/MontBlanc/MONT-BLANC-BOLD.OTF") format("opentype"); /* Modern Browsers */
}
@font-face {
  font-family: "Mont Blanc";
  font-style: normal;
  font-weight: 100;
  src: local("MontBlanc"),
    url("./assets/fonts/MontBlanc/MONT-BLANC-THIN.OTF") format("opentype");
}
@font-face {
  font-family: "Mont Blanc";
  font-style: normal;
  font-weight: 300;
  src: local("MontBlanc"),
    url("./assets/fonts/MontBlanc/MONT-BLANC-LIGHT.OTF") format("opentype");
}
@font-face {
  font-family: "Mont Blanc";
  font-style: normal;
  font-weight: 600;
  src: local("MontBlanc"),
    url("./assets/fonts/MontBlanc/MONT-BLANC-SEMIBOLD.OTF") format("opentype");
}
@font-face {
  font-family: "Mont Blanc";
  font-style: normal;
  font-weight: 900;
  src: local("MontBlanc"),
    url("./assets/fonts/MontBlanc/MONT-BLANC-BLACK.OTF") format("opentype");
}

body {
  margin: 0;
  font-family: "Mont Blanc", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
