/* main colors */

:root {
  --primary-color: #ff5f15;
  --grey-color: #f1f1f1;
  --secondary-color: #fde8cf;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: var(--grey-color) !important;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 1.8rem;
}

h4,
p {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  outline: none !important;
  color: inherit !important;
}

img,
video {
  display: block;
}

*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

ul,
ol {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

input {
  outline: none;
  background-color: transparent;
}

button,
select,
a {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
}

.icon-small {
  width: 25px;
  fill: #000000;
  -webkit-transition: fill 223ms;
  -o-transition: fill 223ms;
  transition: fill 223ms;
  cursor: pointer;
}

.icon-small-active {
  width: 25px;
  fill: var(--primary-color);
  -webkit-transition: fill 223ms;
  -o-transition: fill 223ms;
  transition: fill 223ms;
  cursor: pointer;
}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-align-start {
  display: flex;
  flex-direction: column;
}

.flex-row-center-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center-justify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
  display: flex;
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  background: none !important;
  border: none;
}

.btn-success:hover {
  background: none !important;
  border: none !important;
}

.btn-success {
  background: none !important;
  border: none !important;
}

.btn-success:focus {
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color) !important;
}

.burger-dropdown-menu {
  /*  */
  top: 46px !important;
  left: -165px !important;
}

.user-dropdown-menu {
  top: 55px !important;
  left: -136px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.modal-content {
  border: none !important;

  margin: auto !important;
  /* border-radius: 20px !important; */
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
  justify-content: space-between !important;
}

.btn-simple {
  border: none !important;
  background: none !important;
}

.btn-simple:focus {
  outline: none !important;
  box-shadow: none !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #fa8200 !important;
}

/* .MuiTabs-flexContainer {
    justify-content: center !important;
} */

.btn-prime {
  background: linear-gradient(95deg, #f8ad1a, #ea5e1f) !important;
  border-radius: 50px !important;
}

.rh5v-DefaultPlayer_controls {
  background: none;
}

.rh5v-Overlay_component {
  z-index: 2 !important;
}

.rh5v-Overlay_inner {
  background: url("../assets/icons/btn-play.svg") no-repeat center;
  height: 100%;
}

.rh5v-Overlay_icon {
  display: none;
}

.rh5v-Overlay_inner::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -50%;
}

.rh5v-DefaultPlayer_component,
.rh5v-DefaultPlayer_video {
  border-radius: 10px;
}

/* .rh5v-DefaultPlayer_video {
    width: auto !important;
} */

.rh5v-DefaultPlayer_component {
  background: none !important;
}

.rh5v-Volume_button {
  position: absolute;
  top: 65px;
  right: 113px;

  &:hover {
    background-color: var(--primary-color) !important;
  }
}

.rh5v-Volume_icon {
  padding: 0 !important;
  stroke: white;
  width: 20px;
  height: 20px;
}

.rh5v-Volume_button:hover .rh5v-Volume_slider {
  display: none !important;
}

.rh5v-Volume_slider {
  display: none !important;
  /* position: absolute;
    left: 50px;
    top: 20px;
    height: 56px;
    background-color: #000;
    transform: rotate(90deg); */
}

.rh5v-DefaultPlayer_component:hover .rh5v-DefaultPlayer_controls {
  opacity: 1 !important;
}

.rh5v-DefaultPlayer_controls {
  opacity: 1 !important;
  background-color: transparent !important;
}

.tab-register,
.tab-login {
  width: 50%;
}

.tab-forgot {
  width: 100%;
}

.css-heg063-MuiTabs-flexContainer {
  justify-content: center !important;
}

@media screen and (min-device-width: 1200px) {
  .rh5v-DefaultPlayer_video {
    /* height: 400px !important; */
    width: 100% !important;
  }
}

/* .rh5v-PlayPause_component:hover {} */

.rh5v-PlayPause_button:hover {
  display: none;
}

.rh5v-PlayPause_button {
  display: none;
}

/* //swiper */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100% !important;
  /* margin-right: 50px; */
  /* padding: 100px; */

  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide {
  background: none;
}

.text-left {
  text-align: left;
}

.MuiTextField-root > input::placeholder {
  color: black;
}

.MuiTextField-root > input {
  color: black;
}

.navbar-expand {
  z-index: 2 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary-color) !important;
  border-radius: 0 50px 50px 0 !important;
  margin-left: -81px !important;
  padding-left: 95px !important;
  color: #fff !important;
}

.nav-link {
  color: #000;
}

.nav-link svg.icon-small {
  fill: #fff;
}

.nav-link.login-user > small {
  color: #fff;
}

.recharts-wrapper {
  margin: auto !important;
}

.leftBar {
  margin-top: 100px !important;
}

@media screen and (max-width: 768px) {
  .leftBar {
    margin-top: 0;
  }

  .rh5v-DefaultPlayer_controls {
    background: none !important;
  }

  .modal-content {
    width: 90% !important;
  }

  .rh5v-Volume_button {
    position: absolute;
    top: 58px;
    right: 130px;
  }
}

/* select */

/* .custom-select {
    color: #231f20 !important;
    border: 1px solid transparent !important;
    background-color: transparent !important;
    outline: none;
    outline-color: transparent;
    cursor: pointer;
    font-family: Helvetica !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px !important;
    line-height: 36px !important;
} */

.custom-select:hover,
.custom-select:active,
.custom-select:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Scrollbar */

.scrollBar {
  height: calc(100vh - 180px) !important;
}

/* Scrollbar rail styles */

.ps__rail-y {
  width: 5px !important;
  right: 1% !important;
  border-radius: 6px;
}

@media (min-width: 1600px) {
  .ps__rail-y {
    right: 8% !important;
  }
}

.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-y,
.ps--scrolling-y > .ps__rail-y {
  opacity: 1 !important;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
  background-color: #d5d5d5 !important;
  opacity: 1 !important;
}

/* Scrollbar thumb styles/ */

.ps__thumb-y {
  background-color: var(--primary-color) !important;
  width: 5px !important;
  padding: 0 !important;
  right: 0 !important;
  cursor: pointer;
}

.ps__thumb-x {
  background-color: var(--primary-color) !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: var(--primary-color) !important;
  width: 11px;
}

@media screen and (max-width: 600px) {
  .row-col-sm {
    flex-direction: column;
  }

  .margin-left {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .margin-left {
    margin-left: 24%;
  }
}

@media screen and (max-width: 1220px) {
  .swiper-slide {
    padding: 30px;
  }
}

@media screen and (min-width: 1220px) {
  .swiper-slide {
    padding: 100px;
  }
}

.accordion {
  border: none !important;
}

.accordion__button {
  background: none !important;
}

.accordion__button:before {
  display: none !important;
}

.accordion__heading {
  display: flex;
}

.accordion__heading:after {
  display: inline-block;
  color: orange;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

@media screen and (max-width: 600px) {
  .icon-burger {
    display: block;
    width: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

@media screen and (min-width: 700px) {
  .icon-burger {
    display: none;
  }
}

.css-1azl6jz-MuiTableCell-root,
.css-177gid-MuiTableCell-root {
  text-align: center !important;
}

.css-1azl6jz-MuiTableCell-root,
.css-1ygcj2i-MuiTableCell-root,
.css-10dfkli-MuiTableCell-root,
.css-hbtnrh-MuiTableCell-root {
  border-bottom: none !important;
}

.imageCell::before {
  content: "" !important;
  height: 10px !important;
  width: 10px !important;
  background-color: green !important;
  position: absolute;
}

.rmLvr th,
.rmLvr td,
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root {
  font-size: 12px !important;
  text-align: center !important;
  /* padding : 0 !important; */
}

.red-error {
  color: red;
  font-size: 12px;
  margin-top: 3px !important;
  margin-bottom: 10px;
  font-weight: 500;
}

.red-border {
  border: 2px solid red !important;
  border-radius: 20px !important;
}

.rh5v-Fullscreen_component {
  display: none !important;
}

.label-form {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-left: 18px;
  margin-bottom: 4px;
}

.spinner-submit {
  position: absolute;
  top: 50%;
  left: 45%;
  width: 100px;
  height: 100px;
  /* background: grey; */
}

.spinner-border {
  width: 7rem !important;
  height: 7rem !important;
  border: 1.25em solid currentColor !important;
}

/* .moderate-btn {
    width: 42% !important;
    background: #000 !important;
    border-radius: 20px !important;
    padding: 5px 10px !important;
    color: #fff !important;
    text-transform: none  !important;
} */

.w-40 {
  width: 40% !important;
}

@media screen and (max-width: 600px) {
  .w-40 {
    width: 100% !important;
  }
}

.bg-main {
  background-color: var(--bg-sidebar) !important;
}

.left-border {
  overflow-y: auto;
  padding-right: 20px !important;
  margin-right: -20px;
  padding-left: 20px !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.main-title {
  margin-left: 4px;
  font-size: 28px;
  font-weight: 600;
  line-height: 48px;
  color: var(--primary-color);
}

@media screen and (max-width: 1360px) {
  .main-title {
    font-size: 30px;
    line-height: 40px;
  }

  .mediaLabel {
    font-size: 16px !important;
    line-height: 23px !important;
  }
}

@media screen and (max-width: 1240px) {
  .mediaLabel {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .MuiInputBase-input {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 768px) {
  .left-border {
    border-radius: 20px;
  }
}

@media screen and (max-width: 480px) {
  .bg-main {
    background-color: unset !important;
  }

  .left-border {
    border-radius: 0;
  }
}

.auth-translation {
  .select-translation {
    &__icon {
      width: 20px !important;
      height: 20px !important;
      border-radius: 50%;
      border: none !important;
      object-fit: cover;
    }

    .MuiSvgIcon-root {
      color: black !important;
    }

    &__renderValue {
      color: black !important;

      &__label {
        margin-left: 8px;
        color: black !important;
      }
    }
  }
}

.MuiAutocomplete-popper {
  box-shadow: 0 3px 19px -6px rgba(0, 0, 0, 0.75);
  border-radius: 5px;

  & > div {
    padding: 10px 0;
    border-radius: 5px;
  }

  ul {
    border-radius: 5px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }
}

.flex-justify-between {
  justify-content: space-between;
}

.scrollingY {
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}

#avatar-upload {
  display: none !important;
}

.age_container {
  border-right: 1px solid #e2e2ea !important;

  .select__single-value,
  .select__option {
    font-size: 16px !important;
  }
}

.age_container_border {
  border-radius: 4px;
  border: 1px solid #e2e2ea !important;
}

.label_select_date {
  margin-bottom: 2px;
  display: inline-block;
  color: #9596a2;
  font-size: 16px;
}

.warning-label {
  &:after {
    content: "*";
    margin-left: 4px;
    color: red;
  }
}
