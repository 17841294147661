@import "src/theme";


.icon-button {
    display: inline-flex;
    color: $color-black;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;

    &--dark {
        color: $color-white;
    }

    &--color-primary {
        color: $text-primary  !important;
    }

    &--color-white {
        color: $color-white;
    }

    &--color-black {
        color: $color-black;
    }

    &--size-large {
        width: 48px;
        height: 48px;
    }

    &--size-medium {
        width: 32px;
        height: 32px;
    }

    &--size-small {
        width: 24px;
        height: 24px;
    }
}