$breakpoints: (
    xs: 0,
    sm: 768,
    md: 1241,
    lg: 1361,
    xl: 1536
);


:export {
    @each $key, $value in $breakpoints {
        #{$key}: $value;
    }
}