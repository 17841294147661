.auction-page-title {
  color: #ff5f15;
  font-size: 28px;
  font-weight: 600;
  font-family: "Mont Blanc", sans-serif;
  margin: 0;
}

.table-row {
  display: flex;
  align-items: center;
  height: 115px;
  border-radius: 50px !important;
  font-family: "Mont Blanc", sans-serif;
}

.users-table-row {
  border: 2px solid #ff5f15;
}

.table-row.active td:nth-child(2),
.table-row.active td:last-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.showAddInfoContent .select__menu {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
}

.divider {
  color: #ff5f15;
}

.load-table-head {
  color: #111113 !important;
  font-size: 20px !important;
  font-weight: normal !important;
  border-bottom: none !important;
  font-family: "Inter", sans-serif !important;
  margin-left: 18px !important;
}

.title-load h6 {
  color: #ff5f15 !important;
  font-family: "Inter", sans-serif;
}

.arrow-right {
  font-size: 25px;
  color: #ff5f15 !important;
  cursor: pointer;
  margin-left: 15px;
  transition: transform 0.2s linear;
}

.arrow-right.active {
  transform: rotate(90deg);
}

.table-wrap {
  display: flex;
  font-family: "Inter", sans-serif !important;
}

.MuiTableCell-root {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
}

.MuiTableHead-root th span {
  font-size: 13px !important;
  margin-left: 30px;
}

/*.MuiTableRow-root {*/
/*  background: none !important;*/
/*}*/

/*.MuiTableRow-root td:not(:first-child) {*/
/*  background: #fff !important;*/
/*}*/

/*.MuiTableRow-root td:nth-child(2) {*/
/*  border-top-left-radius: 15px !important;*/
/*  border-bottom-left-radius: 15px !important;*/
/*}*/

.auction .MuiTableRow-root td:nth-child(2),
.charity .MuiTableRow-root td:nth-child(2) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.none-table-items {
  background-color: #f9fafc;
}

.MuiPaper-root.auction-table-paper,
.MuiPaper-root.worst-overload-paper {
  background-color: #f0f1f7 !important;
  box-shadow: none !important;
}

/*.MuiTableRow-root td:nth-last-child(1) {*/
/*  border-top-right-radius: 15px !important;*/
/*  border-bottom-right-radius: 15px !important;*/
/*}*/

.last-cell {
  display: flex;
  align-items: center;
}

.showAddInfo {
  width: 100%;
  background: white;
  padding: 0 16px;
  /*margin-top: -15px;*/
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.index-cell {
  background: #f1f1f1 !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  padding-right: 10px !important;
  margin-left: 5px;
}

.index-cell.select {
  background: inherit !important;
  display: flex;
  align-items: center;
  margin-top: 60%;
}

.MuiBox-root {
  width: 100% !important;
}

.modal .MuiBox-root {
  width: 100% !important;
}

.divider-orange {
  border-bottom: #ff5f15 solid 1px;
  margin-bottom: 10px;
}

.showAddInfo h3 {
  font-size: 20px;
  color: #1d1d1f;
  font-weight: normal;
  text-align: left;
}

.left-column {
  width: 47%;
}

.left-column h3 {
  margin-top: 15px;
}

.description-info textarea {
  width: 100%;
  height: 245px !important;
  background: #f0f1f7;
  border-radius: 10px;
  border: none;
  padding: 20px;
  font-size: 15px !important;
}

.description-info textarea:focus {
  box-shadow: rgba(255, 95, 21, 0.5) 0 0 0 0.2rem !important;
  border: none !important;
  outline: none !important;
}

.interaction-select,
.gender-select {
  width: 100%;
  background: #f0f1f7;
  margin-bottom: 25px;
  height: 50px !important;
  text-align: left;
  border-radius: 10px;
  font-size: 20px !important;
  color: #1d1d1f;
  font-family: inherit !important;
}

.interaction-select {
  margin-bottom: 0;
}

.interaction-label,
.gender-label {
  font-family: inherit !important;
}

.right-column {
  width: 47%;
}

.right-column h3 {
  margin-top: 15px;
}

.right-column .react-select .select__control,
.country .language-option__control,
.left-column .react-select .select__control {
  background-color: #f0f1f7 !important;
}

.rh5v-Volume_component:hover {
  background-color: transparent !important;
}

.description-info h3 {
  margin-top: 0;
}

.showAddInfo .content-info {
  max-width: 550px;
  width: 100%;
  background: #f0f1f7;
  padding: 14px 150px 16px 39px;
  border-radius: 10px;
  margin-bottom: 23px;
  font-size: 15px;
  font-weight: lighter;
}

.description-info .content-info {
  height: 250px;
}

.rh5v-Overlay_inner {
  background: url("src/assets/icons/btn-play.svg") no-repeat center !important;
  height: 100%;
}

.age-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.video-mobile {
  width: 100%;
  margin: 0 auto;
}
.video-table {
  width: 71px !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  margin: 0 auto;
}
.video-table-new{
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 2;
  width: 30px;
}

.video-modal {
  box-shadow: 1px 0 25px -8px rgba(0, 0, 0, 0.73) !important;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  cursor: pointer;
}
.video-modal-close {
  top: 8px;
  right: 8px;
}

.auction__status {
  display: inline-block;
  padding: 0 3px;
  font-weight: 600;
  white-space: nowrap;
}

.auction__status--not-verified {
  color: #f32d1b;
}

.auction__status--verified {
  color: green;
}

.auction__status--verifying-postponed {
  color: #ff5f00;
}

.auction__status-cell {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auction__status-cell-ver {
  background-color: #6daa50;
}

.auction__status-cell-not-verify {
  background-color: #ee1717;
}

.auction__status-cell-postponed {
  background-color: gray;
}
.auctions__actions-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.auction__action {
  cursor: pointer;
  margin: 0 5px;
  color: black;
}
.auction__action-del {
  color: #ee1717 !important;
}

.auction__action-disabled {
  cursor: not-allowed;
  color: gray !important;
  margin: 0 5px;
}
.auction-select-checkbox {
  margin-left: 0 !important;
}
.auction__no_add_video {
  font-weight: 500;
  font-size: 30px;
}

.auction__add_video {
  margin-top: 20px;
  width: 350px;
}

.divider2 {
  border-right: solid #707070 2px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
}

.select-grey {
  width: 100% !important;
  background: #f0f1f7 !important;
}

.showAddInfoContent .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.right-column .language-select {
  width: 100%;
}

.lang-img {
  width: 30px;
}

.lang-img-wrap {
  display: flex;
  align-items: center;
}

.lang-img-wrap img {
  margin-right: 15px;
}

.update-btn {
  max-width: 200px;
  width: 100%;
  float: right;
  margin-bottom: 25px;
}

.css-j204z7-MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.rh5v-Volume_button {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.rh5v-DefaultPlayer_controls {
  z-index: 999 !important;
}

@media screen and (max-width: 1739px) {
  .index-table {
    margin-top: 160px;
  }

  .index {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 1328px) {
  .showAddInfo {
    flex-direction: column;
  }
}
