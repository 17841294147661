@import "src/styles/colors";

.label {
  font-family: "Mont Blanc", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-left: 18px;
  margin-bottom: 4px;

  @media screen and (max-width: 1360px) {
    font-size: 16px !important;
  }

  @media screen and (max-width: 1240px) {
    font-size: 14px !important;
  }
}

.little-label {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.react-select {
  width: 100%;
  position: relative;
  min-height: 50px;
  outline: none;
  background-color: white;
  font-size: 14px;
  font-family: "Mont Blanc", sans-serif;
  border-radius: 10px;

  .select__control--is-disabled {
    .select__placeholder {
      color: rgba(0, 0, 0, 0.38) !important;
    }

    background-color: white !important;
  }

  .select__placeholder--is-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .select__value-container {
    padding-left: 24px;

    @media screen and (max-width: 1360px) {
      padding-left: 12px;
    }

    @media screen and (max-width: 1240px) {
      padding-left: 8px;
    }
  }

  .select__single-value {
    color: $black;
    display: flex;
    align-items: center;
  }

  .select__placeholder {
    color: $grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .select__dropdown-indicator {
    // color: $primary-text-color;
    padding: 0 5px 0 0;

    &:hover {
      //   color: $primary-text-color;
    }
  }

  .select__control--is-focused {
    box-shadow: none;
    outline: none;

    &:hover {
      outline: none;
    }
  }

  .select__control {
    border: none;
    color: $grey;
    min-height: 50px;
    border-radius: 10px;
    height: 100%;
    cursor: pointer;

    &:focus-within {
      box-shadow: rgb(255 95 21 / 50%) 0px 0px 0px 0.2rem !important;
      z-index: 1;
    }

    .language-option__input-container {
      margin: 0 !important;
    }

    .language-option__value-container {
      height: 50px;
      padding: 0;

      .language-option__single-value {
        color: $grey;
        padding: 12px 0 12px 24px;
      }
    }
  }

  .select__option--is-focused {
    background-color: var(--main-content-bg) !important;
    color: $black !important;
  }

  .select__option--is-selected,
  .select__option--is-selected--is-focused,
  .select__option--is-selected:active,
  .select__option--is-focused:active {
    background-color: $primary !important;
    color: $white !important;
  }

  .select__option {
    color: $black;
    padding: 5px 23px 4px 23px;
    border-radius: 10px;
  }
}

.select__multi-value {
  border-radius: 10px !important;
  padding: 0 6px;
}

.select__value-container--is-multi {
  padding-left: 5px !important;

  .select__placeholder {
    margin-left: 22px !important;

    @media screen and (max-width: 1360px) {
      margin-left: 12px !important;
    }

    @media screen and (max-width: 1240px) {
      margin-left: 4px !important;
    }
  }
}

.select__multi-value__remove {
  border-radius: 50% !important;
  height: 22px;
  width: 22px;
  margin-top: 5px;
  background: #cdcdcd;
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: center;

  @media (max-width: 1360px) {
    height: 17px;
    width: 17px;
  }

  @media (max-width: 1240px) {
    height: 15px;
    width: 16px;
  }
}

.select__menu {
  z-index: 10 !important;
  outline: none !important;
  -webkit-box-shadow: 0 13px 34px -12px rgba(0, 0, 0, 0.23) !important;
  box-shadow: 0 13px 34px -12px rgba(0, 0, 0, 0.23) !important;
  border-radius: 10px !important;
  border: none !important;
  margin-top: -12px !important;
  padding: 23px 9px 9px 11px;
  font-size: 14px;
}

.select__menu-list {
  max-height: 130px !important;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}
