.auto-select {
    &--open &__angle {
        transform: rotate(180deg);
    }
    
    &__clear {
        display: none;
    }

    &--filled &__clear {
        display: block;
    }
}

.auto-select-paper {
    margin-top: -10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
