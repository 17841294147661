.buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    font-family: "Mont Blanc", sans-serif;
}

.cancel-btn {
    background: #ffffff;
    outline: solid 1px #ff5f15;
    color: #111113;
    padding: 5px 30px;
    border-radius: 10px;
    max-width: 200px;
    width: 100%;
    height: 50px;
    margin-right: 25px;
}

.upload-btn {
    background: #ff5f15;
    outline: solid 1px #ffffff;
    color: #ffffff;
    padding: 5px 30px;
    border-radius: 10px;
    max-width: 350px;
    width: 100%;
    height: 50px;
}

.MuiOutlinedInput-notchedOutline {
    border: 2px solid #000 !important;
}

.border-black {
    border: 2px dashed #707070;
    border-radius: 20px;
    font-size: 20px;
    font-weight: lighter;
}

.border-black span {
    color: #ff5f15;
    margin-top: 10px;
    font-weight: normal;
}

.upload__image-wrapper button {
    width: 100%;
    height: 240px;
    background: #ffffff;
    font-family: "Mont Blanc", sans-serif;
}

.support-title {
    color: #ff5f15;
    font-size: 28px;
    font-weight: 600;
    font-family: "Mont Blanc", sans-serif;
    padding-bottom: 40px;
}

.support-page__container {
    padding: 0 27px;

    @media screen and (max-width: 600px) {
        padding-bottom: 50px;
    }
}

.support-page__commentPadding {
    padding-top: 26px;
}

.image-item {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
}

.image-item img {
    height: fit-content;
    margin-right: 25px;
}

.image-item button {
    height: 50px;
    width: 100px;
    border-radius: 10px;
}

.form-wrap h4 {
    font-size: 20px;
    font-weight: 600;
    color: #1d1d1f;
    padding-left: 20px;
    padding-bottom: 5px;
    font-family: "Mont Blanc", sans-serif;
}

.form-wrap textarea {
    border-radius: 10px;
    width: 100%;
    border: none !important;
    height: 250px !important;
    padding-left: 25px;
    padding-top: 13px;
    font-family: "Mont Blanc", sans-serif;
    font-size: 20px;
}

.upload-wrap {
    max-width: 430px;
    width: 100%;
    margin: auto;
}

.message {
    display: block !important;
    border-top: none !important;
}

.messageTitle {
    background-color: #fff !important;
    padding-bottom: 14px !important;
    border-radius: 30px;
    border: 1px solid #ff5f15;
}

.messageContainer {
    background-color: #fff !important;

}