@import "./variables";


.w-100 {
    width: 100%;
}

@for $i from 0 through 12 {
    .pl-#{$i} {
        padding-left: #{$spacing * $i}#{$unit};
    }

    .ml-#{$i} {
        margin-left: #{$spacing * $i}#{$unit};
    }

    .pr-#{$i} {
        padding-right: #{$spacing * $i}#{$unit};
    }

    .mr-#{$i} {
        margin-right: #{$spacing * $i}#{$unit};
    }

    //.p-#{$i} {
    //    padding: #{$spacing * $i}px;
    //}

    //.pt-#{$i} {
    //    padding-top: #{$spacing * $i}px;
    //}

    //.px-#{$i} {
    //    padding-left: #{$spacing * $i}px;
    //    padding-right: #{$spacing * $i}px;
    //}

    //.mt-#{$i} {
    //    margin-top: #{$spacing * $i}px;
    //}

    //.mb-#{$i} {
    //    margin-bottom: #{$spacing * $i}px;
    //}

    //.mx-#{$i} {
    //    margin-left: #{$spacing * $i}px;
    //    margin-right: #{$spacing * $i}px;
    //}
}