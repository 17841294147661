.pieContainer {
  background-color: #f9fafc;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 6px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
}
.percentContainer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  height: 82px;
  padding: 8px 13px 8px 11px;
  width: 100%;
  justify-content: space-around;
  margin: 0 auto;
}
.percent {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.percent + .percent {
}

.colorsContainer {
  padding: 17px 37px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.colorPoint {
  transform: translateY(39px);
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.name {
  font-weight: 600;
  font-size: 10px;
  color: #9596a2;
  margin-bottom: 12px;
}
.value {
  font-size: 16px;
  color: #1d1d1f;
  font-weight: 600;
}
.label {
  color: #9596a2;
  font-size: 13px;
  position: absolute;
  max-width: 100px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
