@import "src/styles/colors";

.budgetContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.containerVideoModal {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;

  @media (max-width: 920px) {
    padding-right: 30px !important;
  }
}

.budgetValue {
  white-space: nowrap;
}

.budgetValue + .budgetValue {
  margin-left: 20px;
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: var(--primary-color);
    margin-right: 5px;
  }
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

// .input {
//     /*width: 80% !important;*/
// }

.price {
  display: block;
  min-width: 210px;
  text-align: left;
  white-space: nowrap;
}

.span {
  // color: $primary;
  font-weight: bold;
}

.buttonsContainer {
  display: flex;
  margin-top: 37px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 740px) {
    flex-direction: column;
    margin-top: 1.5rem;
  }

  &__footerText {
    font-size: 18px;
    font-weight: 500;
  }

  &__block {
    width: 346px;
    display: flex;

    @media (max-width: 740px) {
      width: 100%;
      margin-top: 40px;
    }

    & > div {
      &:first-child {
        margin-right: 22px;
      }
    }

    & > div,
    button {
      width: 162px !important;
      font-size: 16px;
    }

    & > div:first-child {
      @media (max-width: 740px) {
        width: 100% !important;
      }
    }
  }
}

.buttonContainer {
  width: fit-content;
}

.button {
  background-color: $primary;
  border-radius: 10px;
  width: 175px;
  max-width: 175px;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  display: block;
  line-height: 1.5;
  color: #fff;
  font-family: "Mont Blanc", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;

  @media screen and (max-width: 768px) {
    padding: 10px 0;
    max-width: 120px;
    width: 120px;
  }

  @media screen and (max-width: 500px) {
    padding: 10px 0;
    max-width: 100px;
    width: 100px;
  }
}

.topContainer {
}

.videoTitle {
  font-size: 24px;
  font-weight: 600;
  width: 100%;
}

.videoDescription {
  font-weight: 400;
  width: 100%;
  font-size: 12px;
}

.videoContent {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: wrap;

  &__containerVideo {
    height: 162px;
    width: 288px;
    border-radius: 10px;

    @media (max-width: 920px) {
      width: 100%;
      height: 100%;
    }

    & > div {
      height: 100%;
    }

    video {
      background-color: white;
      border-radius: 10px;
    }

    &_video {
      width: 100%;
      height: 100%;
      background: white;
    }
  }

  &__containerBlock {
    display: flex;
    justify-content: space-between;

    @media (max-width: 920px) {
      width: 100%;
      justify-content: flex-end;
      margin-top: 1.5rem;
    }

    @media (max-width: 740px) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  &_block {
    min-width: 162px;
    max-width: 162px;
    background-color: white;
    border-radius: 10px;
    color: #1d1d1f !important;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    padding: 20px 20px;
    align-items: center;
    text-align: center;
    margin-left: 22px;
    justify-content: center;

    span {

      @media (min-width: 740px) {
        -webkit-line-clamp: 1; /* количество строк */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;

      }

    }

    &:first-child {
      margin-left: 0;

      @media (max-width: 920px) {
        width: 100%;
        max-width: none !important;
      }
    }

    @media (max-width: 740px) {
      width: 100%;
      max-width: none !important;
      margin-left: 0;
      margin-top: 1.5rem;
    }

    span {
      font-size: 26px;
    }

    p {
      margin-top: 15px !important;
      font-size: 12px;
    }
  }
}

.rates {
  text-align: right;
}

@media (max-width: 650px) {
  .budgetContainer {
    flex-direction: column;
  }

  .budgetValue + .budgetValue {
    margin-left: 0;
  }
}

.notMoneyContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
}

.done {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  color: $green;
  width: 50px !important;
  height: 50px !important;
}

.logo {
  width: 150px;
  height: 134px;
  @media screen and(max-width: 768px) {
    width: 120px;
    height: unset;
  }
}

.logo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-top: 30px;
  @media screen and(max-width: 768px) {
    margin-top: 15px;
    font-size: 20px;
  }
}

.secondText {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  @media screen and(max-width: 768px) {
    margin-top: 10px;
    font-size: 16px;
  }
}

.cancel {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ee1717;
  width: 50px !important;
  height: 50px !important;
}

.budgetButton + .budgetButton {
  margin-left: 5px;
}

.backBtn {
  color: $primary;
  font-size: 35px !important;
  cursor: pointer;
  position: absolute;
  top: -48px;
  left: -16px;
}

.error_block {
  box-shadow: red 0px 0px 0px 2px;
}