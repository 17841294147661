.transaction-wrapper {
  position: relative;
  margin-bottom: 50px;
}

.table-opened {
  max-height: 100% !important;
}

.transaction-buttons {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  height: 30px;
  border-radius: 10px;
  background-color: #e2e2ea;
  padding: 2px;
  font-size: 14px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
    white-space: nowrap;
    height: 100%;
  }
}

.active-button {
  background-color: var(--primary-color);
  border-radius: 10px;
  color: white;
}

.transaction-table {
  background-color: #f9fafc;
  border-radius: 10px;
}

.table-head {
  background-color: #e2e2ea;
  height: 40px;
  border-radius: 10px;

  div {
    color: #9596a2;
    font-size: 12px;
    text-align: center;
    line-height: 12px;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.tr-table-row {
  display: flex;

  div {
    display: flex;
    width: 15%;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    &:nth-child(3), &:nth-child(4), &:nth-child(5) {
      width: 12%;
    }

    &:last-child {
      width: 34%;
    }
  }
}

.tr-table-row-hold {
  display: flex;

  div {
    display: flex;
    width: 10.5%;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    &:nth-child(3) {
      width: 8%;
    }
    &:first-child {
      width: 20%;
    }
    &:last-child {
      width: 30%;
    }
  }
}

.table-body {
  &__row {
    height: 60px;
    border-bottom: 2px solid #e2e2ea;

    div {
      font-size: 13px;
      font-weight: 300;
      border-right: 2px dashed #e2e2ea;
      color: #1d1d1f;

      &:last-child {
        border: none !important;
      }
    }

    .green {
      color: #10be83;
    }

    .red {
      color: #ff4949;
    }

    @media screen and (max-width: 768px) {
      height: fit-content;

      div {
        font-size: 10px;
      }
    }

    &:last-child {
      border: none !important;
    }
  }
}

.arrow-button {
  background-color: var(--primary-color);
  border-radius: 50%;
  z-index: 1;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.4s ease-in-out !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;

  &:hover {
    opacity: 0.8;
  }
}

.dont-transactions-block {
  padding: 20px 10px;
  color: #9596a2;
  text-align: center;
}

.current_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &__block {
    margin-top: 10px;
    display: flex;
    font-size: 14px;
    align-items: center;

    & > div {
      &:first-child {
        font-size: 16px;
        font-weight: 500;
      }

      &:last-child {
        background-color: #e2e2ea;
        padding: 4px 7px;
        border-radius: 10px;
        margin-left: 10px;
        color: #9596a2;
      }
    }
  }

  &__select {
    display: flex;
    justify-content: space-between;
    width: 40%;
    & > div {
      width: 45%;
    }
  }
}
