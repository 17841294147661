.auth-date-group {
  &__container {
    display: flex;
    border: 1px solid #e2e2ea;
    border-radius: 4px;

    &--is-focused {
      border-color: #819bea;
      box-shadow: 0 1px 4px 0 #819bea;

      .auth-date-group__select {
        border-color: #819bea !important;
      }
    }
  }

  &__label {
    font-size: 15px;
    font-weight: 500;
    color: #9596a2;
    margin-bottom: 6px;
  }

  &__select {
    width: 100%;
    font-size: 16px;
    border-right: 1px solid #e2e2ea;

    &:nth-child(1) {
      width: 23%;
    }

    &:nth-child(2) {
      width: 47%;
    }

    &:last-child {
      border: 0 !important;
      width: 30%;
    }
    &__indicator {
      padding: 4px 8px 4px 2px !important;
    }

    &__control {
      border: 0 !important;
      min-height: 50px !important;
      &--is-focused {
        box-shadow: none !important;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      z-index: 111111 !important;
      padding: 5px 0 !important;
      &-list {
        padding: 0 !important;
        position: relative !important;

        @media (min-width: 1024px) {
          &::-webkit-scrollbar {
            width: 4px;
            position: absolute;
            right: 0;
            background: transparent;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: transparent;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #9596a2;
            border-radius: 3px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #888;
          }
        }
      }
    }
    &__option {
      &--is-focused {
        background: #e2e2ea !important;
      }
      &--is-selected {
        background: var(--primary-color) !important;
        color: white !important;
      }
    }
  }
}
