@import "src/styles/colors";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(12px);
}
.saveBtn {
  cursor: pointer;
  font-size: 15px;
  color: $grey;
}
.validSave {
  color: $primary;
}

.arrowsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.arrow {
  cursor: pointer;
  color: $primary;
  font-size: 25px !important;
}

.downArrow {
  transform: rotate(180deg);
}

.disabledArrow {
  color: $grey;
  cursor: unset;
}

.inputClass {
  min-width: 110px;

  :global(.text-input__wrapper) {
    width: fit-content;
    max-width: 150px;
    height: unset;
  }
  :global(.text-input__left-addons) {
    padding-left: 4px;
  }
  :global(.text-input__input) {
    padding: 4px 0;
  }
  :global(.text-input__right-addons) {
    padding-right: 4px;
  }
}
