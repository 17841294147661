@import "src/styles/base.scss";

.navbarGrey {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #fff;
  color: #000;
  width: 100%;
}

.logo {
  font-weight: 600;
}

.version>span {
  font-size: 0.8em;
}

.circle-user {
  border-radius: 50%;
  background-color: var(--primary-color);
  text-align: center;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.pro-sidebar {
  height: 100vh !important;
  width: auto !important;
  min-width: 100% !important;
}

.pro-sidebar-content {
  margin-left: 23px !important;
  margin-top: 50px !important;
  transition: margin-left ease-in-out 0.3s;
}

.pro-sidebar>.pro-sidebar-inner {
  background: #fff !important;
  height: 100% !important;
}

@media screen and (min-width: 1500px) {
  .pro-sidebar>.pro-sidebar-inner {
    height: 100vh !important;
  }
}

.pro-sidebar-footer {
  margin-left: 30px !important;
}

.pro-sidebar-header,
.pro-sidebar-footer {
  border: none !important;
}

.nav-item {
  color: #000 !important;
  height: 25px;
  display: flex;
  align-items: center;
}

.text-decoration-none:focus {
  /*border-right: 5px solid orange !important;*/
  /*border-bottom: 1px solid orange !important;*/
}

.sidebar-wrapper {
  padding-right: 15px;
  transition: padding-right ease-in-out 0.3s;
}

.mobile-sidebar-close {
  color: white;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-right: 10px;
}

.dashboard-link {
  padding: 0.8rem 2rem;
  border-radius: 10px;
  margin-bottom: 20px;
  display: block;
}

.dashboard-text {
  display: inline-block;
  margin-left: 15px;
  white-space: nowrap;
  word-spacing: 1px;
}

.icon path {
  fill: var(--primary-color);
}

.icon g#company path,
.icon g#company line {
  fill: none;
  stroke: var(--primary-color);
}

.dashboard-link.active {
  background-color: var(--primary-color);
}

.dashboard-link.active .icon path {
  fill: #ffffff;
}

.dashboard-link.active .icon g#company path,
.dashboard-link.active .icon g#company line {
  fill: none;
  stroke: white;
}

.dashboard-link .icon path#pencil {
  fill: transparent;
}

.dashboard-link.active .icon path#pencil {
  fill: var(--primary-color);
  stroke: #fff;
}

.rotated {
  transform: rotate(180deg);
}

.dashboard-wrapper {
  margin-left: 32px;
  color: #fff;
  white-space: nowrap;
  margin-bottom: 49px;
}

.dashboard-wrapper img {
  object-fit: cover;
  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
}

.dashboard-wrapper:last-child {
  margin-bottom: 0;
}

/*MUI*/
.dashboard-wrapper .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.dashboard-wrapper .MuiSelect-select {
  color: #fff !important;
  font-weight: 500;
}

.dashboard-wrapper svg {
  position: relative;
}

.dashboard-wrapper svg path {
  fill: #fff;
}

.left-bar-logo {
  margin-left: 42px;
  margin-top: 20px;
  transition: margin-left ease-in-out 0.3s;
}

.sidebar-user-info {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.select-translation {
  &__option {
    display: flex;
    align-items: center;
    color: black;
  }

  &__select {
    color: white;
  }

  &__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid black 2px;
    object-fit: cover;
  }

  &__label {
    padding-left: 12px;
    font-weight: bold;
    color: black;
  }

  .MuiSvgIcon-root {
    color: white;
  }
}

.dashboard-language-select {
  display: flex;
  margin-bottom: 49px;
  margin-left: 2rem;
}

@media (max-width: 1024px) {
  .sidebar-wrapper {
    padding-right: 5px;
  }

  .pro-sidebar-content {
    margin-left: 11px !important;
    margin-top: 50px !important;
  }

  .dashboard-link {
    padding: 0.7rem 1.7rem;
  }

  .dashboard-language-select {
    margin-left: 1.7rem;
  }

  .left-bar-logo {
    margin-left: 26px;
  }
}

@media (max-width: 768px) {
  .pro-sidebar-content {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 768px) {
  .sidebar-user-info {
    display: none;
  }
}

@media (max-width: 480px) {


  .mob-d-none {
    display: none;
  }

  .sidebar-wrapper {
    padding-right: 0;
  }
}

.auth-translation {
  .select-translation {

    &__icon {
      width: 20px !important;
      height: 20px !important;
      border-radius: 50%;
      border: none !important;
      object-fit: cover;
    }

    .MuiSvgIcon-root {
      color: black !important;
    }

    &__renderValue {
      color: black !important;

      &__label {
        margin-left: 8px;
        color: black !important;
      }
    }
  }
}