:root {
  overflow: hidden;
}

body[data-theme="light"] {
  --body-bg: white;
  --color: black;
  --back-sidebar: white;
  --main-content-bg: #f0f1f7;
  --back-form: white;
  --back-scroll: #d5d5d5;
  --bg-sidebar: #111113;
}

body[data-theme="dark"] {
  --body-bg: black;
  --color: white;
  --back-sidebar: #231f20;
  --main-content-bg: #262122;
  --back-form: white;
  --back-scroll: #000;
  --bg-sidebar: rgba(42, 39, 37, 0.98);
}

.bg-brown {
  background-color: var(--main-content-bg) !important;
}

.page {
  background-color: var(--main-content-bg) !important;
  color: var(--color) !important;
  padding: 40px 40px 40px 20px !important;

  @media (max-width: 770px) {
    .page {
      padding-top: 40px;
      padding-bottom: 70px;
    }
  }

  @media (max-width: 480px) {
    padding: 80px 5px 60px 5px !important;
  }
}
.container {
  @media screen and(min-width: 2300px) {
    max-width: 1800px;
  }
}

.page::-webkit-scrollbar {
  display: none;
}

.css-j204z7-MuiFormControlLabel-root {
  color: #000 !important;
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: var(--bg-sidebar) !important;
}

.main-container {
  padding-right: 15px;
  width: auto;
  height: 100vh !important;
  @media screen and (max-width: 480px) {
    padding-right: 0;
  }
}

.pro-sidebar-layout {
  padding-right: 10px;
}

.pro-sidebar-layout::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.pro-sidebar-layout::-webkit-scrollbar-track {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: transparent;
}

.pro-sidebar-layout::-webkit-scrollbar-thumb {
  background-color: #6c6c6c;
  width: 3px;
  min-height: 150px;
  border-right: none;
  border-left: none;
  border-radius: 4px;
}

.pro-sidebar-layout::-webkit-scrollbar-track {
  margin: 100px 0;
  border-radius: 4px;
  background: transparent;
}

.pro-sidebar-layout::-webkit-scrollbar-track-piece {
  border-radius: 4px;
  background-color: transparent;
}

.accordion__button,
.page-heading,
.custom-select {
  color: var(--color) !important;
}

.nav-item {
  color: #fff !important;
}

.icon-mode {
  background: var(--color) !important;
}

.padd-none {
  padding: 0 !important;
}

.padd-none-right {
  padding-right: 0 !important;
}

.css-19kzrtu {
  background: var(--back-form) !important;
}

/* button  {
    color: var(--color) !important; 
  } */
//.MuiInputLabel-outlined {
//  transform: translate(15px, 14px) scale(1) !important;
//}
/* .row>* {
      padding: 0 !important;
  } */
/* body {
    color: var(--color) !important; 
    background-color: var(--body-bg) !important; 
   
  } */

.ps__rail-y {
  background: var(--back-scroll) !important;
}

.flex-row-wrapper {
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-row-wrapper::-webkit-scrollbar {
  width: 0;
}

.rh5v-Volume_component:hover {
  background-color: transparent;
}

.font-thin {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.ldzOjB table tbody tr {
  border-radius: 50% !important;
  padding: 20px !important;
}

.row {
  flex-wrap: nowrap !important;
}

textarea:focus-visible {
  outline: none;
  box-shadow: 0 0 9px -5px rgba(0, 0, 0, 0.61);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mobile-padding {
  padding: 15px 0;
  max-width: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.td-underline-white {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: white;
    height: 1px;
    left: 0;
    width: 100%;
  }
}

.td-underline-primary {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: var(--primary-color);
    height: 1px;
    left: 0;
    width: 100%;
  }
}

.td-underline-black {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: black;
    height: 1px;
    left: 0;
    width: 100%;
  }
}

.acceptCookieButton {
  border-radius: 12px !important;
  padding: 12px 42px !important;
  color: white !important;
  font-weight: bold;
  line-height: inherit;
  background-color: rgb(255, 95, 0) !important;
}

.introjs-helperLayer {
  box-shadow: rgb(0 0 0 / 80%) 0 0 0 0, rgb(33 33 33 / 50%) 0 0 0 5000px !important;
  border-radius: 10px !important;
}

.introjs-tooltip {
  border-radius: 20px !important;
  min-width: 500px !important;
  width: 100% !important;
  background-color: #f0f1f7 !important;
  .introjs-skipbutton {
    font-weight: 300 !important;
    font-size: 28px !important;
  }
}

.introjs-arrow {
  display: none !important;
}

.introjs-tooltipReferenceLayer {
  * {
    font-family: "Inter", sans-serif !important;
  }
}

.introjs-tooltipbuttons {
  display: flex !important;
  justify-content: center !important;

  .introjs-button {
    border-radius: 10px !important;
    border: 0 !important;
    text-shadow: none !important;
    padding: 0.6rem 1.5rem !important;
    margin: 0 5px !important;
  }

  .introjs-nextbutton {
    background: var(--primary-color);
    color: white !important;
  }

  .introjs-prevbutton {
    background-color: #e0e0e0 !important;
  }

  .introjs-disabled {
    display: none !important;
  }
}

.MuiCardHeader-action {
  margin: 0 !important;
}

.pointer {
  cursor: pointer;
}

.hover-opacity {
  transition: hover 0.1s linear;
  &:hover {
    opacity: 0.9;
  }
}
