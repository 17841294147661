@import "src/theme";

.data-table-row {
  border: none;
  box-shadow: none;
  background: none !important;

  &--open {
    padding-bottom: 0 !important;
  }

  .select_inTable {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }

  .zeroPadding {
    padding: 0 !important;
  }

  &__cell {
    transition: border 0.2s linear;
    background-color: #f9fafc;
    padding: 11px 5px 7px !important;
    border-bottom: 1px solid #e2e2ea !important;
    overflow: hidden;
    font-size: 11px !important;

    &__grey {
      background-color: #d7d7d7 !important;
    }

    & > div {
      text-align: center !important;
    }
  }

  &--open &__cell {
    &:nth-child(1) {
      border-bottom-left-radius: 0;
    }

    &:last-child {
      border-bottom-right-radius: 0;
    }
  }

  &--content &__cell {
    padding: 0;
  }

  &--content &__cell {
    &:nth-child(2) {
      border-top-left-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 0;
    }
  }

  &--open + &--content &__cell {
    border-top-left-radius: 0 !important;
  }

  &__data {
    padding: 10px;
    background-color: #ffffff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &--content &__data {
    border-top: $color-primary solid 1px;
  }

  &__icon {
    transform: rotate(-90deg);
    transition: transform 0.2s linear;
  }

  &--open &__icon {
    transform: rotate(0);
    border-bottom-left-radius: 15px !important;
  }
}

.data-table-row {
  &:nth-last-child(1) {
    .data-table-row__cell {
      border-bottom: 0 !important;
    }
  }
}

.data-table-row-separator {
  border: none;
  box-shadow: none;

  &--open {
    padding-bottom: 0 !important;
  }

  td {
    transition: border 0.2s linear;
    height: 6px;
    padding: 0 !important;
    background-color: $color-primary !important;
    font-size: 12px !important;

    &:first-child {
      border-radius: 25px 0 0 25px !important;
    }

    &:last-child {
      border-radius: 0 25px 25px 0 !important;
    }
  }
}

.data-table-dashed-cell {
  border-left: dashed 1px #e2e2ea !important;
}
