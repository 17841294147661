@import "src/styles/colors";

.container {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: center;

  &__avatar,
  &__settings,
  &__notifications {
    width: 50px !important;
    height: 50px !important;
    background: var(--main-content-bg) !important;
    margin: 0 4px !important;
  }
  &__info {
    max-width: 0;
    overflow: hidden;
    @media screen and (min-width: 1440px) {
      transition: all ease-in-out 0.5s;

      &_text {
        white-space: nowrap;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_open {
        max-width: 100%;
        margin: 0 1rem;
      }
    }
  }

  &__badge {
    span {
      background: $primary !important;
      color: white !important;
      font-size: 8px !important;
      min-width: 15px !important;
      height: 15px !important;
      line-height: 2% !important;
    }
  }
}

@media screen and (min-width: 1440px) {
  .info {
    margin-top: 10px;
    max-height: 40px;
    transition: max-height ease-in-out 0.5s;
    overflow: hidden;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__hidden {
      max-height: 0;
      transition: max-height ease-in-out 0.5s;
    }
  }
}
