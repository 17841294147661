.author {}

.author-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    height: 372px;
    filter: brightness(0.5);
}

.author-info {
    position: absolute;
    top: 92px;
    font-family: Helvetica;
    font-style: normal;
    color: #ffffff;
}

.author-avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.author-name {
    font-weight: 500;
    font-size: 35px;
    line-height: 42px;
}

.author-description {
    font-weight: 100;
    font-size: 18px;
    line-height: 20px;
}

.page-heading {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    color: #231f20;
    margin: 32px 0 36px 0 !important;
}

.video-item {
    background-color: #ffffff;
    filter: brightness(0.5);
    border-radius: 35px;
    height: 170px;
    margin: 0 0px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .author-description {
        font-size: 20px;
        line-height: 24px;
        width: 90%;
    }
}

@media (min-width: 1300px) {
    .author-description {
        width: 70%;
    }
}