.companyItem {
  background: white;
  border-radius: 10px !important;
  width: calc(1 / 2 * 100% - (1 - 1 / 2) * 1rem);
  margin: 0 0 1rem;


  &__title {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__button {
    color: var(--primary-color) !important;
  }
  @media (max-width: 1200px) {
    &__title {
      max-width: 100px;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    &__title {
      max-width: 200px;
    }
  }
}
