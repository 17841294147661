@import "src/theme";

.textarea {
  display: inline-flex;
  flex-direction: column;

  &--full-width {
    width: 100%;
  }

  &__label {
    color: #1d1d1f;
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 4px;

    &--warrning {
      color: #1d1d1f;
      font-size: 20px;
      font-weight: 600;

      &::after {
        content: "*";
        margin-left: 4px;
        color: red;
        font-size: 20px;
      }
    }

    @include down("lg") {
      font-size: 16px;
    }

    @include down("md") {
      font-size: 14px;
    }
  }

  &__input {
    width: 100%;
    height: 100px !important;
    background: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 20px;
    font-size: 14px;

    &:focus {
      box-shadow: rgba(255, 95, 21, 0.5) 0 0 0 0.2rem !important;
      border: none !important;
      outline: none !important;
    }
    &::placeholder {
      color: $color-muted;
    }
  }

  &--variant-grey &__input {
    background: #f0f1f7;
  }
}
