.grid-item-nav {
  width: 100% !important;
  flex-basis: 100% !important;

  &:first-child {
    max-width: 45% !important;
  }

  &:last-child {
    max-width: 55% !important;
  }

  @media (max-width: 1000px) {
    &:first-child {
      max-width: 100% !important;
    }

    &:last-child {
      max-width: 100% !important;
    }
  }
}

.status_send-verify {
  font-size: 12px !important;
  height: 40px !important;
  width: 100%;
  padding: 5px 12px !important;
  min-width: 120px;
  background-color: var(--toastify-color-success);
}

.button_delete {
  background-color: var(--toastify-color-error);
  font-size: 14px !important;
  height: 40px !important;
}
.content-table {
  scrollbar-gutter: auto;
  margin-top: 20px;
  table {
    tbody {
      tr {
        td {
          height: 107px;
        }
      }
    }
  }
}
.modal-content-table {
  overflow: auto;
  height: 324px;
  table {
    tbody {
      tr {
        td {
          height: 89px !important;
        }
      }
    }
  }
}

.content-table-actions {
  width: 95px !important;
  padding-right: 0 !important;
}
.desktop-table-container{
  @media screen and (max-width: 768px){
    display: none;
  }
}
.mobile-off{
  display: none;
}
.mobile-table-container{
  @media screen and (min-width: 769px){
    display: none;
  }
}