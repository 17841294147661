.code-input {
  &__label {
    font-size: 15px;
    font-weight: 500;
    color: #9596a2;
    margin-bottom: 6px;
  }
  &__input {
    input {
      margin: 15px 15px 15px 0px;
      width: 50px;
      border-radius: 10px;
      background-color: white;
      font-size: 20px;
      height: 50px;
      padding-left: 19px;
      border: 1px solid rgb(226, 226, 234);
    }
    &--four {
      input {
        border: 1px solid var(--primary-color);
      }
    }

    &--error {
      input {
        border: 1px solid red !important;
      }
    }

    &--success {
      input {
        border: 1px solid green !important;
      }
    }
  }
}
