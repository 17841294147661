.play,
.pause {
    position: relative;
}

.play:hover::before,
.pause:hover::before {
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    -webkit-transition: 223ms;
    -o-transition: 223ms;
    transition: 223ms;
    z-index: 2 !important;
}

.play:hover::before {
    background-image: url('../../assets/icons/pause_icon.svg') !important;
}

.pause:hover::before {
    background-image: url('../../assets/icons/play-video.svg') !important;
}

@media (min-width: 1400px) {
    .info {
        margin-left: -74px;
    }
}

.lonely-player-wrapper {
    position: relative;
}

.hovering {
    position: relative;
}

.hovering:hover::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #231f2080;
    border-radius: 10px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    -webkit-transition: 223ms;
    -o-transition: 223ms;
    transition: 223ms;
}

.goBack {
    position: absolute;
    content: '';
    top: 5%;
    left: 5%;
    display: block;
    background-image: url('../../assets/icons/arrow-go-back.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 60px;
    height: 60px;
    padding: 10px;
    z-index: 2;
}