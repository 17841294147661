.auction {
  font-family: "Mont Blanc", sans-serif !important;

  @media (max-width: 420px) {
    position: relative;
  }

  & h4,
  & h5 {
    font-weight: 600;
    margin: 0;
  }

  & table {
    width: 100%;

    .MuiTableSortLabel-root svg {
      display: none;
    }

    .showAddInfo {
      margin-left: -16px;
      width: calc(100% + 32px);
      padding: 0 16px 0 60px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      margin-top: -15px;
    }

    .divider-orange {
      margin-bottom: 0;
    }

    .collapsed-content {
      padding: 10px 0;
    }

    .arrow-right {
      transition: transform 0.2s linear;

      &.active {
        transform: rotate(90deg);
      }
    }
  }

  .auction-header {
    justify-content: flex-end;
  }
}

.selectCountryAuction {
  display: flex;
  align-items: center;
  min-width: 300px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

.selectDateAuction {
  display: flex;
  align-items: center;
  min-width: 360px;
  justify-content: space-between;

  @media (max-width: 1200px) {
    margin-top: 20px;
    width: 100%;
  }

  @media (max-width: 450px) {
    width: 100%;
    margin-right: 0 !important;
    min-width: auto;

    button {
      margin-right: 0 !important;
    }
  }

  & h5 {
    font-size: 16px;
    width: max-content;
    margin-right: 10px;
  }
}

.auctionButtons {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.auctionButton {
  background-color: var(--primary-color);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 16px;
  width: 48%;
  display: block;
  line-height: 1.5;
  color: #fff;
  font-family: "Mont Blanc", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.auctionBackIcon {
  margin-bottom: 45px;
  max-width: 16px;
  max-height: 16px;

  & line {
    stroke: var(--primary-color);
  }
}

.auctionHr {
  height: 2px;
  background-color: #fff;
  margin: 23px auto;
}

.dividerOrange {
  background-color: var(--primary-color);
}

.auctionCategories {
  display: grid;
  padding: 0 24px;
  margin-top: 40px;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 40px;
  grid-auto-rows: 1fr;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.auctionCategory {
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  line-height: 1.05;
  transition: color 0.2s linear, background-color 0.2s linear;
  min-height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.auctionModal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modalDialog {
  max-width: 940px !important;
  width: 100%;
}
.modalContent {
  max-height: 90vh;
}
.modalButton {
  white-space: nowrap;
  background-color: #ff5f15 !important;
  color: white !important;
  padding: 10px 16px 12px !important;
  border-radius: 10px !important;
  outline: none;
  border: none;
  font-family: "Mont Blanc", sans-serif;
  font-size: 20px;
  font-weight: 500;

  &:first-child {
    margin-right: 7px;
  }

  &:last-child {
    margin-left: 7px;
  }
}

.addButton {
  text-transform: uppercase;
  margin-bottom: 0;
}

.editBtn {
  padding: 5px 15px !important;
  font-size: 16px;
  display: block;
  margin: 5px auto 0;
  white-space: nowrap;
  background-color: #ff5f15 !important;
  color: white !important;
  border-radius: 10px !important;
  outline: none;
  border: none;
  font-family: "Mont Blanc", sans-serif;
  font-weight: 500;
}

.country {
  width: 260px;

  @media (max-width: 770px) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.auctionHiddenBack {
  display: none;
}

.containerPickers {
  width: 300px;
  display: flex;
  margin-left: 10px;

  @media (max-width: 977px) {
    margin-left: 0;
    margin-top: 5px;
  }

  & > div {
    &:last-child {
      margin-left: 10px;
    }
  }
}

.selectDate {
  margin-top: 4px;
  width: 300px;
}

.tableButtonAllShow {
  font-family: "Inter", sans-serif !important;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  margin-top: 1rem;

  &__left {
    display: flex;

    &_country {
      margin-right: 1rem;
    }
  }
  &__right {
    margin-left: 1rem;
    button {
      white-space: nowrap;
    }
  }

  @media (max-width: 770px) {
    padding-right: 0;
    flex-direction: column !important;
    align-items: start;

    &__left {
      flex-direction: column !important;
      width: 100%;

      &_country {
        margin-right: 0;
      }
    }

    &__right {
      width: 100%;
      margin: 1rem 0;
    }
  }
}
.countryContainer {
  margin-left: 0 !important;
}
