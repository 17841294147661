.select-translation {
  &__option {
    display: flex;
    align-items: center;
    color: black;
  }

  &__input {
    padding-left: 0 !important;
  }

  &__select {
    padding-left: 0 !important;
  }

  &__renderValue {
    color: white;

    &__label {
      margin-left: 8px;
      color: white;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  &--dark &__icon {
    border-color: #ffffff;
  }

  &__label {
    padding-left: 12px;
    font-weight: bold;
    color: black;
  }

  .MuiSvgIcon-root {
    color: white;
  }

  .MuiInput-input:focus {
    background: transparent !important
  }
}

.auth-translation {
  .select-translation {

    &__icon {
      width: 20px !important;
      height: 20px !important;
      border-radius: 50%;
      border: none !important;
      object-fit: cover;
    }

    .MuiSvgIcon-root {
      color: black !important;
    }

    &__renderValue {
      color: black !important;

      &__label {
        margin-left: 8px;
        color: black !important;
      }
    }
  }
}