@use "sass:math";

@import "./variables";
@import "./breakpoints.module";


@mixin up($key: "") {
    @if map-has-key($breakpoints, $key) {
        @media (min-width: #{map-get($breakpoints, $key)}#{$unit}) {
            @content;
        }
    }
    @else {
        @warn "`#{$key}` is not a valid key for `breakpoints` mixin.";
    }
}



@mixin down($key: "") {
    @if map-has-key($breakpoints, $key) {
        @media (max-width: #{map-get($breakpoints, $key) - math.div($step, 100)}#{$unit}) {
            @content;
        }
    }
    @else {
        @warn "`#{$key}` is not a valid key for `breakpoints` mixin.";
    }
}
