.modal-cookie {
    z-index: 100000002 !important;

    .cookie-modal-text {
        padding: 0 10px;

        p,
        div {
            line-height: 1.4;
            color: #000;
            font-weight: 600;

        }

        p {
            font-size: 14px;
        }

        div {
            text-decoration: underline;
            font-size: 12px;
            margin-top: 20px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        .show {
            display: -webkit-box;
            -webkit-line-clamp: 3; // количество строк
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    .cookie-modal-content {
        margin-top: 10px;
        overflow-y: auto;
        max-height: 400px;
        padding: 0 10px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.2);
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.4);
        }

        &::-webkit-scrollbar-thumb:active {
            background: rgba(0, 0, 0, 0.9);
        }

        .cookie-modal-accardion {
            border-radius: 20px !important;
            margin-top: 10px;

            &::before {
                display: none !important;
            }

            .container-panel {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .cookie-modal-accardion-panel-title {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #000;
                    text-transform: capitalize;
                }

                span,
                .cookie-always {
                    color: #000;
                    font-size: 12px;
                    font-weight: 500;
                }

                .container-accardion-checkbox {
                    display: flex;
                    align-items: center;

                    .accardion-checkbox {
                        margin-left: 10px;
                        height: 20px;
                        width: 38px;
                        border-radius: 34px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        padding: 0 4px;
                        justify-content: flex-end;

                        div {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: white;
                        }
                    }
                }
            }

            .cookie-modal-accardion-panel {
                flex-direction: row-reverse !important;

                .MuiAccordionSummary-content {
                    margin-left: 10px !important;
                }

                .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                    transform: rotate(90deg);
                }

            }

            .cookie-modal-accardion-content {
                padding: 0 20px !important;
                overflow: hidden !important;
                overflow-x: auto !important;

                .cookie-modal-accardion-content-text {
                    font-size: 14px;
                    line-height: 1.4;
                    color: #000;
                }
            }
        }
    }

    .modal-cookie-submit {
        display: flex;
        width: 100%;
        margin-top: 20px;
        padding: 0 10px;
        justify-content: flex-end;

        button {
            padding: 10px 15px;
            background-color: var(--primary-color);
            color: #ffffff;
            border-radius: 10px;
        }
    }
}

.cookie-container {
    z-index: 100000001;
    align-items: center;
    background: white;
    display: flex;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 100%;
    bottom: 0px;
    padding: 14px 25px;
    font-family: "Mont Blanc";
    box-shadow: 0px -3px 16px -7px rgba(0, 0, 0, 0.35);

    @media (max-width: 600px) {
        flex-wrap: wrap;
        padding: 14px 15px;
    }
}

.cookie-content {
    font-size: 11pt;
    line-height: 18px;
    text-align: start;

    @media (max-width: 600px) {
        font-size: 8pt;
    }
}


.cookie-container-buttons {
    flex-direction: row;
    display: flex;
    margin-left: 10px;

    @media (max-width: 430px) {
        justify-content: space-between;
        width: 100%;
    }


    .cookie-button {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
        padding: 9px 12px;
        transition: opacity 0.2s linear;
        margin-left: 10px;

        &:hover {
            opacity: 0.8;
        }

        @media (max-width: 600px) {
            font-size: 10px;
        }

        @media (max-width: 430px) {
            padding: 9px;
            margin-left: 0;
        }
    }


    .cookie-accept-button {
        background-color: var(--primary-color);
        color: white;
    }

    .cookie-decline-button,
    .cookie-settings-button {
        color: #333333;
        background-color: #dedfe0;
    }

    @media (max-width: 600px) {
        margin-left: 0 !important;
        margin-top: 20px;

        .cookie-button {
            &:first-child {
                margin-left: 0 !important;
            }
        }
    }
}


.cookie-necessary-table {
    margin-top: 30px;
    width: 100%;
    border-radius: 5px;
    min-width: 500px;

    @media (max-width: 770px) {}

    .cookie-necessary-table-head {
        background-color: var(--primary-color) !important;

        li {
            color: white !important;
            font-weight: 600 !important;
        }
    }


    .cookie-necessary-table-row {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        padding: 15px 10px !important;
        align-items: center;
        background: #F3F5F7;
        padding: 10px;
        margin-bottom: 0 !important;
        border-bottom: 1px solid #939393;

        li {
            color: black;
            list-style-type: none;
            width: 100%;

            &:first-child {
                max-width: 30%;
            }

            &:nth-child(2) {
                max-width: 20%;
            }

            &:nth-child(3) {
                max-width: 50%;
            }
        }
    }
}