.accordion {
  border-radius: 20px !important;
  margin-bottom: 1rem !important;
  background: var(--main-content-bg) !important;
  &::before {
    display: none !important;
  }

  &__summary {
    padding: 0px 4px 0px 16px !important;
  }
}

.text {
  white-space: pre-line;
}

.title {
  text-align: center;
  width: 100%;
  transition: width ease-in-out 0.5s !important;
  white-space: nowrap;
  &__open {
    width: 0px;
  }
}
