@import "../../styles/colors";

.companies {
  font-family: "Mont Blanc", sans-serif !important;

  &__list * {
    font-family: "Inter", sans-serif !important;
  }
}

.pageTitle {
  color: $primary;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}

.createBtn {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.btnContainer {
  width: auto;
}

.companiesContainer {
  display: grid;
  grid-gap: 25px 30px;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;

  @media screen and(max-width: 1240px) {
    grid-template-columns: 1fr;
  }
}

.column {
  margin-bottom: 48px;
  margin-top: 89px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;

  @media screen and(max-width: 1240px) {
    grid-template-columns: 1fr;
  }
}

.companyItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  width: 100%;
  padding: 11px 15px 12px 24px;
  background-color: white;
  color: $black;
}

.loader {
  height: 50%;
  margin-top: 20px;
}
