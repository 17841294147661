@import "src/styles/colors";

.container {
  * {
    font-family: "Inter", sans-serif !important;
  }

  height: calc(100vh - 30px);
  z-index: 2;
  background-color: white;
  border-radius: 20px;
  width: 220px;
  flex-shrink: 0;
  transition: max-width ease-in-out 0.5s;
  transition-property: max-width, width;
  overflow: hidden;
  max-width: 320px;
  padding: 1rem;

  @media screen and (max-width: 1440px) {
    max-width: 200px;
    width: 200px;
  }
  @media screen and (max-width: 1240px) {
    max-width: 190px;
    width: 190px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }

  &__open {
    max-width: 400px;
    width: 400px;
    @media screen and (max-width: 1440px) {
      max-width: 290px;
      width: 290px;
    }
    @media screen and (max-width: 1240px) {
      max-width: 250px;
      width: 250px;
    }
  }
}

.toggle {
  position: absolute !important;
  left: 100%;
  transform: translateX(-100%);
  transition: all ease-in-out 0.5s !important;

  svg {
    transition: all ease-in-out 0.5s;
    transform: rotate(0deg);
  }

  &__open {
    transform: translateX(0);
    left: 0;

    svg {
      transform: rotate(180deg);
    }
  }
}

.faq {
  padding-left: 10px;
  overflow: auto;
  max-height: calc(100vh - 245px);
}

.faqTitle {
  overflow: hidden;
  white-space: nowrap;
}
