@import "src/styles/colors";
.container {
  background-color: #f9fafc;
  border-radius: 10px;
  width: 100%;
  padding: 14px 18px 20px 20px;
  margin-bottom: 20px;
}

.header {
  margin-bottom: 33px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.twoColTable {
  display: flex;
}
.col + .col {
  margin-left: 119px;
}
.col {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  align-items: center;
  margin: 0 -20px;
  padding: 5px 20px 7px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
}

.item {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lightRow {
  background-color: white;
}
.mapInfoContainer {
  display: inline-flex;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.option{
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}

.mapRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6.5px 0 7.5px;
}
.mapRow + .mapRow {
  border-top: 1px solid #f0f1f7;
}

.mapTableItem {
  width: 168px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 600;
}

.map {
  stroke: white !important;
  width: 100%;
  stroke-width: 2 !important;
  height: 100%;
  max-height: 500px;
}
.flag{
  width: 24px;
  height: 16px;
}