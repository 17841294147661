@import "src/styles/colors";
.fullWidth {
  width: 100%;
  .language-option__control {
    background-color: white !important;
  }
  .language-option__option {
    display: flex;
    img {
      margin-right: 12px;
    }
  }
  .language-option__menu {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    border: none !important;
    margin-top: -10px !important;
    padding: 23px 9px 0 11px;
    img {
      width: 38px;
    }
    z-index: 10;
  }
  .language-option__option--is-focused {
    background-color: var(--main-content-bg) !important;
    color: $black !important;
  }

  .language-option__option--is-selected,
  .language-option__option--is-selected:active,
  .language-option__option--is-focused:active {
    background-color: $primary !important;
    color: $white !important;
  }
  .language-option__option {
    color: $black;
    padding: 5px 23px 4px 23px;
    border-radius: 10px;
  }
  .language-option__menu-list {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }
}
