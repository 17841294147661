@import "src/styles/colors";
.dropdown {
  font-family: sans-serif;
  position: relative;
  width: 100px;
  border-radius: 4px;
  padding: 8px;
}

.dropdown-label {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  color: $grey;
}

.dropdown-label-icon {
  margin-right: 8px;
}

.dropdown-options {
  z-index: 999;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border-top: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

.dropdown-options--open {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.dropdown-options-option {
  padding: 8px;
  cursor: pointer;
  color: $grey;
}

.dropdown-options-option:hover {
  background-color: #f0f0f0;
}
