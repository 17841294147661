@import "src/styles/colors";

.container {
  background-color: $light  !important;
  overflow: hidden;
}

.modal_overlay {
  opacity: 0.8 !important;
  z-index: 1055 !important;
}

.header {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  padding-right: 45px;
  button {
    border-radius: 50%;

    &:focus {
      box-shadow: none;
    }
  }
}

.close {
  cursor: pointer;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  color: $grey;
  right: 20px;
}