@import "../../styles/colors";

.title {
  color: $black;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  position: relative;
}

.pdf {
  border-radius: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 900px;
  flex-grow: 1;
  overflow-y: auto;

  iframe {
    body {
      background-color: white !important;
    }
  }

  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: white;
    height: 500px;
    max-height: 500px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    width: 8px;
    min-height: 150px;
    border-right: none;
    border-left: none;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    margin: 100px 0;
    border-radius: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    border-radius: 4px;
    background-color: white;
  }
}

.confirm,
.cancel {
  width: fit-content;
  margin-top: 15px;
}

.cancel {
  margin-left: 15px;

  button {
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.confirm + .confirm {
  margin-left: 10px;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame {
  #scroller {
    overflow: hidden;
  }
}
