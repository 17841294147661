.container {
  height: 100%;
}

.title {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

.content {
  * {
    font-family: "Inter", sans-serif !important;
  }
}

.item {
  padding: 1.25rem 0;
  border-top: 1px solid #dbdbe5;
  width: 100%;

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;

    @media (max-width: 900px) {
      flex-direction: column !important;
    }
  }

  &__label {
    max-width: 320px;
    width: 100%;
  }

  &__field {
    max-width: 420px;
    width: 100%;
    margin-left: 1rem;

    @media (max-width: 900px) {
      margin-left: 0;
      margin-top: 10px;
      max-width: 100%;
    }
  }
}

.cancel,
.save {
  padding: 10px 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: fit-content !important;
  white-space: nowrap;
}

.containerButton {
  margin: 0 6px !important;
}

.accordion {
  border-radius: 10px !important;
  margin-bottom: 1rem !important;
  &::before {
    display: none !important;
  }
}

.all {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &__open {
    transform: rotate(180deg) !important;
  }

  &::before {
    content: "";
    width: 100%;
    border-top: 1px solid #dbdbe5;
    position: absolute;
    height: 0;
    background: gray;
    top: 15px;
  }
  button {
    background: var(--primary-color) !important;
  }
}

.select_navigation {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.buttonFull {
  @media (max-width: 900px) {
    margin-bottom: 1rem !important;
  }
}
