.container {
  * {
    font-family: "Inter", sans-serif !important;
  }
  width: 100% !important;
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: var(--primary-color);
    margin-right: 5px;
  }
}

.required {
  color: #ee1717 !important;
  margin-left: 3px;
}

.header {
  background: white;
  height: 150px;
  width: 100%;
  position: relative;
  border-top-left-radius: 70px;
  margin-bottom: 140px !important;

  @media (max-width: 1025px) {
    height: 120px;
    margin-bottom: 100px !important;
    border-top-left-radius: 40px;
  }

  @media (max-width: 600px) {
    height: 100px;
    margin-bottom: 80px !important;
  }

  @media (max-width: 425px) {
    border-top-left-radius: 40px;
  }

  &__content {
    position: absolute;
    bottom: -100px;
    padding: 0 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1025px) {
      bottom: -70px;
    }

    @media (max-width: 600px) {
      padding: 0 10px;
    }
  }

  &__preview {
    border-top-left-radius: 70px;
    object-fit: cover;

    @media (max-width: 1025px) {
      border-top-left-radius: 50px;
    }

    @media (max-width: 425px) {
      border-top-left-radius: 40px;
    }
  }

  &__upload,
  &__delete {
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    cursor: pointer;

    svg {
      color: var(--primary-color);
    }
  }
  &__delete {
    background: transparent;
    border: 0;
    backdrop-filter: brightness(0.9) blur(5px);

    &:hover {
      opacity: 0.9;
    }

    svg {
      color: white;
    }
  }
}

.avatar {
  background: white;
  min-width: 140px;
  min-height: 140px;
  max-width: 140px;
  max-height: 140px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 0px 4px var(--main-content-bg);
  position: relative;

  @media (max-width: 1025px) {
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }

  @media (max-width: 426px) {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  }

  svg {
    color: var(--primary-color);
    margin-bottom: 5px;
  }

  &:hover {
    svg {
      opacity: 0.9;
    }
  }

  &__preview {
    width: 100%;
    height: 100%;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
    &:hover {
      .avatar__delete {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__delete {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.1s linear, opacity 0.1s linear;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    backdrop-filter: brightness(0.9) blur(5px);
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: white;
      margin-bottom: 0;
    }
  }
}

.item {
  padding: 1.25rem 0;
  border-bottom: 1px solid #dbdbe5;
  width: 100%;
  display: flex;

  &:last-child {
    border-bottom: 0 !important;
  }

  &__block {
    display: flex;
    width: 100%;
    max-width: 800px;
    justify-content: space-between;

    @media (min-width: 2200px) {
      max-width: 1200px;
    }

    @media (max-width: 1025px) {
      flex-direction: column !important;
    }
  }

  &__left {
    max-width: 320px;
    width: 100%;
    margin-right: 1rem;

    @media (max-width: 1025px) {
      max-width: 100%;
    }

    @media (min-width: 2200px) {
      max-width: 440px;
    }
  }

  &__right {
    max-width: 420px;
    width: 100%;

    @media (max-width: 1025px) {
      max-width: 100%;
      margin-top: 1rem;
    }

    @media (min-width: 2200px) {
      max-width: 660px;
    }
  }
}

.actions {
  display: flex;

  @media (max-width: 1260px) {
    display: none;
  }
}

.actions_mob {
  display: none;

  @media (max-width: 1260px) {
    display: flex;
  }
}

.company_button {
  border: 1px solid red;

  svg {
    margin-bottom: 3px;
    margin-right: 5px;
    color: red;
  }
}

.company_title {
  white-space: nowrap !important;
}

.navigate {
  @media (max-width: 700px) {
    flex-direction: column !important;
    align-items: start !important;

    .back {
      margin-bottom: 1rem;
    }
  }
}

.button {
  padding: 10px 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: fit-content !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
