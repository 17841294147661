@import "src/styles/colors";
.container {
  height: 700px;
  max-height: 60vh;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 150px;
  height: 134px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.text {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-top: 30px;
}
.done {
  position: relative;
  margin-top: 30px;
  color: $green;
  width: 50px !important;
  height: 50px !important;
}

