@import "../../../styles/colors";
@import "src/theme";

.countryLabel {
  font-size: 20px;
  font-weight: 600;
  margin-left: 18px;
  margin-bottom: 4px;

  @media screen and (max-width: 1360px) {
    font-size: 16px !important;
  }

  @media screen and (max-width: 1240px) {
    font-size: 14px !important;
  }
}

.countryValueName {
  @media screen and (max-width: 992px) {
    margin-left: 8px;
  }
}

.language-select {
  width: 100%;
  height: 50px;
  font-size: 14px;
  outline: none;
  background-color: white;
  border-radius: 10px;

  .language-option__control--is-disabled {
    .language-option__single-value {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  .language-option__value-container {
    padding-left: 16px;
    font-size: 14px;
  }

  .language-option__single-value {
    color: $black;
    display: flex;
    align-items: center;
    z-index: 3;
  }

  .language-option__placeholder {
    color: $color-muted;
  }

  .language-option__dropdown-indicator {
    padding: 0 5px 0 0;
  }

  .language-option__control--is-focused {
    box-shadow: none;
    outline: none;

    &:hover {
      outline: none;
    }
  }

  .language-option__control {
    border-radius: 10px;
    border: none;
    min-height: 32px;
    height: 100%;
    cursor: pointer;

    &:focus-within {
      box-shadow: rgb(255 95 21 / 50%) 0px 0px 0px 0.2rem;
    }

    .language-option__input-container {
      margin: 0 !important;
    }

    .language-option__value-container {
      height: 50px;

      .language-option__single-value {
        padding: 12px 0 12px 0;

        img {
          margin-right: 1rem;
          width: 32px;
          border-radius: 2px;
          object-fit: cover;
        }
      }
    }

    &.grey {
      background: #f0f1f7;
    }
  }

  .language-option__menu {
    img {
      width: 38px;
    }

    z-index: 10;
  }

  .language-option__option--is-selected,
  .language-option__option--is-selected--is-focused,
  .language-option__option--is-selected:active,
  .language-option__option--is-focused:active {
    background-color: $light !important;
  }

  .language-option__option--is-focused {
    background-color: var(--main-content-bg) !important;
  }
  .language-option__option {
    padding: 8px 4px;
    color: $black;
    align-items: center;
    &--is-focused {
      background-color: var(--main-content-bg) !important;
    }
  }
}

.language-option__menu {
  z-index: 10 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  border: none !important;
  margin-top: -10px !important;
  padding: 23px 9px 0 0px;
}

.language-option__menu-list {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}
