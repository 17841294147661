.delete-icon {
    cursor: pointer;


    &.icon--mask {
        mask-image: url("./images/delete-icon.svg");
        color: red;
    }

    &.icon--background {
        background-image: url("./images/delete-icon.svg");
    }

}