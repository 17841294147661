@import "src/styles/base.scss";

.black-btn {
  border: 2px solid #000 !important;
  border-radius: 50px !important;
  padding: 0 20px !important;
}

.black-btn:focus {
  border: 2px solid var(--primary-color) !important;
}

.opacity-low {
  opacity: 0.5;
}

.opacity-high {
  opacity: 1;
}

.wallet {
  overflow-y: auto;

}

.wallet-page__grid {
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.wallet-page__title {
  margin-right: 10px !important;
}

.wallet-cards {
  display: flex;
  flex-direction: column;
}

.wallet-card {
  background-color: #f9fafc;
  border-radius: 20px;
  box-shadow: 0 1px 2px 0 #00000016;
  padding: 40px;
  min-height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;

  @media screen and (max-width: 900px) {
    min-width: 100%;
  }

  &:first-child {
    margin-bottom: 40px;
  }

  &__title {
    color: #9596a2;
    font-size: 16px;
    font-weight: 300;
  }

  &__name {
    margin-top: 10px;
    margin-bottom: -10px;
    color: #9596a2;
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    display: block;
  }

  &__sum {
    color: #1d1d1f;
    font-size: 40px;
    font-weight: 400;
  }
}

.wallet_input-conteiner {
  position: relative;

  span {
    position: absolute;
    color: #9596a2;
    font-size: 30px;
    letter-spacing: 1.5px;
    right: 5px;
  }
}

.wallet_input_sum {
  outline: none;
  border: none;
  border-bottom: 1px solid #1d1d1f;
  width: 100%;
  height: 50px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: #1d1d1f;
  letter-spacing: 1.5px;

  &::placeholder {
    color: #9596a2;
    font-size: 24px;
    letter-spacing: 1.1px;
  }
}

.wallet-paymentSystem {
  display: flex;
  justify-content: space-evenly;

  div {
    padding: 0 15px;
    min-width: 120px;
    height: 60px;
    border-radius: 7px;
    border: 1px solid #f9fafc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    font-size: 10px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.active-payment {
  background-color: white;
}

.wallet-deposit-button {
  text-transform: uppercase;
  height: 40px;
  font-weight: 400 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

@font-face {
  font-family: "OCRA-Becker-Rus";
  src: local("OCRA-Becker-Rus"),
    url("../../assets/fonts/OCRAbeckerrus_lat.otf") format("opentype");
}

.wallet-common-card {
  background: linear-gradient(
    220deg,
    rgba(139, 139, 139, 1) 0%,
    rgba(60, 60, 60, 1) 100%
  );
  border-radius: 10px;
  overflow: hidden;
  height: fit-content;
  position: relative;
  padding-top: 43.64%;
}

.wallet-trans-field {
  background-color: lightgrey;
  border-radius: 10px;
}

@font-face {
  font-family: PoeMonospace;
  src: local("PoeMonospace"),
    url("../../assets/fonts/PoeMonospace.ttf") format("truetype");
}

.wallet-field-mft {
  font-family: PoeMonospace;
  //font-weight: 400;
  //font-size: 18px;
}

.wallet-field-mftb {
  font-family: PoeMonospace;
  font-weight: bold;
  //font-size: 18px;
}

.wallet-rtls-main {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  direction: rtl;
}

.wallet-rtls-main > * {
  direction: ltr;
}

.wallet-rtls-block {
  max-height: 8rem;
}

.wallet-rtls-blk-report {
  max-height: 32rem;
}

.wallet-balance {
  margin-bottom: 13px;
  font-size: 24px;
}

.wallet-text {
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: end;
  justify-content: flex-end;
}

.wallet-text-primary {
  color: var(--primary-color);
}

.wallet-number {
  font-size: 20px;
  font-weight: 300;
  margin-top: 6vw;
}

.wallet-title {
  color: white;
  font-size: 20px;
  margin: auto 0 0;
  font-weight: bold;
  margin-top: 15px;
}

.wallet-title-reserve {
  color: rgba(255, 255, 255, 0.721);
  font-size: 20px;
  margin: auto 0 0;
  font-weight: bold;

  @media (max-width: 430px) {
    margin-top: 10px;
  }
}

.wallet-chart {
  max-width: 100%;
  margin-bottom: 57px;
}

.wallet-chart .recharts-wrapper {
  width: 100% !important;
}

.wallet-page__pay {
  margin-bottom: 20px;
}

.wallet-next {
  top: 75%;
  right: 10px;
  cursor: pointer;
}

.wallet-back {
  top: 75%;
  right: 12px;
  transform: translateY(30px);
  cursor: pointer;
}

.wallet-next svg {
  transform: rotate(180deg);
}

.wallet-next svg path {
  fill: var(--primary-color);
}

.wallet-svg-fill {
  fill: var(--primary-color);
}

.wallet-back svg path {
  fill: var(--primary-color);
}

.wallet-button + .wallet-button {
  margin-left: 10px;
}

.wallet-methods {
  border-radius: 10px;
}

.wallet-method {
  width: 25%;
  border-radius: 10px;
  border: 1px solid #707070;
  margin: 0 10px;
  cursor: pointer;
}

.wallet-method.active {
  border-color: var(--primary-color);
}

.wallet-method:first-child {
  margin-left: 0;
}

.wallet-method:last-child {
  margin-right: 0;
}

.text-main {
  color: var(--primary-color);
  font-weight: 500;
  line-height: 1;
}

.wallet-br {
  height: 2px;
  background-color: #6c6c6c;
  margin: 0 -16px;
}

/* CHART */
.wallet .recharts-wrapper {
  margin: 0 !important;
  padding-top: 0 !important;
  max-width: 100%;
  height: auto !important;
  max-height: 100%;
  padding-right: 30px;
}

.wallet .recharts-wrapper svg {
  width: 100%;
  height: auto;
}

.wallet .recharts-cartesian-axis-line {
  display: none;
}

.wallet .recharts-cartesian-axis-line[orientation="left"],
.wallet .recharts-cartesian-grid-horizontal,
.wallet .recharts-cartesian-grid-vertical,
.wallet .recharts-cartesian-axis-tick-line {
  display: none;
}

.wallet.recharts-text.recharts-cartesian-axis-tick-value[orientation="bottom"] {
  transform: translateY(10px);
}

.wallet .MuiSvgIcon-colorWarning {
  display: none;
}

.wallet .MuiFormHelperText-root {
  position: absolute;
  top: 100%;
}

.wallet-edit {
  margin-left: 10px;
}

#date {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#cvv {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.date-input:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  display: block;
  background-color: #707070;
}

.question {
  color: #6c6c6c;
  font-size: 20px;
  cursor: pointer;
}

.wallet-button[disabled] {
  background-color: #707070;
}

.wallet-subtitle {
  border-bottom: 2px solid var(--primary-color);
  font-size: 30px !important;
}

.auctionDateArrow {
  margin: 5px 0 0 11px;
  transform: rotate(-90deg);
  transition: transform 0.2s linear;
  max-width: 14px;
  max-height: 14px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5.5px 0 5.5px;
  border-color: #707070 transparent transparent transparent;
  line-height: 0;
}

.paypalModal {
  width: 100%;
  max-width: 500px !important;

  @media screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}

.payOkBtn {
  margin: 0 auto;
  width: 40%;
}

.payCloseBtn {
  top: 8px;
  right: 8px;
}

.paySuccessBalance {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.paySuccessBalance + .paySuccessBalance {
  margin-bottom: 20px;
}

.table-wrapper {
  margin: 40px 0 60px 0;
  background-color: white;
  border-radius: 10px;

  .table-content-filter {
    padding: 10px;
  }
}

.table-content-filter {
  & > div {
    &:last-child {
      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

.table-filter-block {
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;

    & > div {
      width: 100%;
    }

    .filter-search {
      max-width: 100% !important;
    }
  }

  .react-select .select__value-container {
    padding: 2px 8px !important;
  }

  .react-select {
    min-width: 170px !important;
    font-size: 16px !important;
  }

  .react-select,
  .select__control {
    min-height: auto !important;
  }

  .select__menu {
    top: 50px !important;
  }
}

.table-transactions {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-spacing: 0 5px;
  max-width: 100%;
  min-height: fit-content;
  border-radius: 3px;

  .table-body {
    max-height: 300px;
    transform: scaleX(-1);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: block;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-color);
      width: 4px;
      min-height: 150px;
      border-right: none;
      border-left: none;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      border-radius: 4px;
      background-color: white;
    }

    .table-body-container {
      transform: scaleX(-1);
    }
  }

  .table-head {
    width: 100%;
    padding: 0 10px;
    background-color: #f3f2f7;
    border-color: currentcolor #ebe9f1 #ebe9f1;
    border-top: 1px solid #ebe9f1;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;

      li {
        color: #6e6b7b;
        width: 100%;
        font-weight: 600;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          &::after {
            border-style: solid;
            border-width: 0.1em 0.1em 0 0;
            content: "";
            display: inline-block;
            height: 0.5em;
            right: 0;
            margin-left: 0.5em;
            position: relative;
            top: 0.5em;
            transform: rotate(-225deg);
            vertical-align: top;
            width: 0.5em;
          }
        }

        &:last-child {
          &:hover {
            &::after {
              display: none;
            }
          }

          cursor: default;
        }

        &:first-child {
          max-width: 100px !important;
        }

        &:last-child {
          max-width: 110px !important;
        }
      }
    }
  }

  .table-transactions-row {
    padding: 0 10px !important;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    min-height: 50px;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: none !important;
    }

    li {
      width: 100%;
      font-weight: 600;
      padding: 5px 10px;

      &:first-child {
        max-width: 100px !important;
      }

      &:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 110px;

        div {
          cursor: pointer;
          width: 35px;
          background-color: #ff5f15;
          border-radius: 10px;
          height: 32px;

          &:hover {
            opacity: 0.8;
          }

          svg {
            fill: white;
          }

          &:first-child {
            padding: 2px 4px;
          }

          &:last-child {
            padding: 1.5px 5.5px;
          }
        }
      }
    }
  }
}

.table-content-filter {
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-wrap: wrap;

    & > div:last-child {
      .table-filter-block {
        margin-left: 0 !important;
      }
    }
  }

  .filter-search {
    height: 40px;
    max-width: 200px;
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
  }

  h5 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .auctionDate input + div {
    left: 0 !important;
  }
}

.wallet-buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 830px) {
  .table-head ul li {
    font-size: 14px !important;
    padding: 5px !important;

    &:last-child {
      min-width: 80px;
    }
  }

  .table-transactions-row li {
    font-size: 14px !important;
    padding: 5px !important;

    &:last-child {
      div {
        margin-left: 3px;
        padding: 4px 5.5px !important;
      }
    }
  }
}

@media (max-width: 1440px) {
  .wallet-title {
    font-size: 20px;
  }

  .wallet-text {
    font-size: 16px;
  }

  // .wallet-page__title {
  //   margin-bottom: 80px !important;
  // }

  .wallet {
    justify-content: start;
  }
}

@media (max-width: 1320px) {
  .wallet-title {
    font-size: 20px;
  }

  .wallet-text {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .wallet .MuiInputBase-input,
  .wallet-button {
    font-size: 16px;
  }

  .wallet-text {
    font-size: 14px;
  }

  .wallet-title {
    font-size: 20px;
  }
}

.marginTop {
  margin-top: 12px;
}

@media (max-width: 1160px) {
  .wallet-common-card,
  .wallet-transactions {
    margin-top: 56px;
  }

  .wallet-page__grid {
    grid-template-columns: 1fr;
  }

  .wallet .MuiInputBase-input,
  .wallet-button {
    font-size: 14px;
  }

  .wallet-text {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  .wallet .MuiInputBase-input,
  .wallet-button {
    font-size: 18px;
  }

  .wallet-text {
    font-size: 14px;
  }

  .wallet-title {
    font-size: 22px;
  }

  .payOkBtn {
    width: 80%;
  }
}

.select__control--menu-is-open {
  svg {
    transform: rotate(-268deg) !important;
    top: 0.625em !important;
  }
}

.active-sort {
  &::after {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: "";
    display: inline-block;
    height: 0.5em;
    right: 0;
    margin-left: 0.5em;
    position: relative;
    transform: rotate(-45deg) !important;
    top: 0.75em !important;
    vertical-align: top;
    width: 0.5em;
  }
}

.successStatus {
  color: green;
}

.pendingStatus {
  color: #ff5f15;
}

.table-download {
  padding: 5px 10px;
  cursor: pointer;
  width: 45px;
  background-color: #ff5f15;
  border-radius: 10px;
  height: 40px;

  svg {
    fill: white;
  }

  &:hover {
    opacity: 0.8;
  }
}

.header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    flex-wrap: wrap;

    h4 {
      @media screen and (max-width: 600px) {
        margin-bottom: 15px !important;
        width: 100%;
      }
    }
  }

  h5 {
    font-size: 14px;
    color: #9596a2;
    font-weight: 400;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }
}
.wallet-fail-pay {
  height: fit-content !important;
}
