.hashtag {
    /* display: flex; */
    align-items: center;
    padding: 0;
    margin: 0;
    font-size: 12px;
}

.hashtag-item {
    margin: 0 4px 0 0;
}

@media screen and (max-width: 600px) {
    .mob-padding {
        padding: 10px !important;
    }
}
.title {
    font-size: 20px;
}