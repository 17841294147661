@import "src/theme";

.video-input {
  font-family: "Mont Blanc", sans-serif;
  &__label {
    font-family: "Mont Blanc", sans-serif;
    color: #1d1d1f;
    font-size: 20px;
    font-weight: 600;
    padding-left: 18px;
    padding-bottom: 4px;

    @include down("lg") {
      font-size: 16px;
    }

    @include down("md") {
      font-size: 14px;
    }
  }

  &__wrapper {
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;

    input {
      display: none;
    }

    label {
      cursor: pointer;
      width: 100%;
      background-color: white;
      height: 50px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding-left: 24px;

      @media screen and (max-width: 1360px) {
        padding-left: 14px;
      }
      @media screen and (max-width: 1240px) {
        padding-left: 10px;
      }

      @media screen and (max-width: 600px) {
        font-size: 12px;
      }

      span {
        display: flex;
        align-items: center;
        height: 100%;
        border-radius: 0 10px 10px 0;
        padding: 0 15px;
        color: white;
        background-color: var(--primary-color);
      }
    }
  }

  &__helper-text {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 500;
    marginleft: 4px;
  }

}
