@import "src/styles/colors";

.auth {
  height: 100vh;
  background: #f9fafc;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: 1024px) {
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #9596a2;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }

  * {
    font-family: "Inter", sans-serif !important;
  }

  &__container {
    max-width: 1400px;
    padding: 30px 20px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1400px) {
      padding: 30px 40px;
    }

    @media (max-width: 460px) {
      padding: 30px 15px 100px 15px;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_logo {
      height: 50px;
    }
  }
  &__modern {
    position: fixed;
    background-image: url(../../assets/images/Pattern.png);
    height: 260px;
    min-width: 3000px;
    width: 100%;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;

    @media (max-width: 1000px) {
      justify-content: center;
    }

    &_form {
      height: fit-content;
      max-width: 550px;
      width: 100%;
      padding: 50px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 4px 0 #e2e2ea;
      z-index: 1;

      @media (max-width: 460px) {
        padding: 30px 20px;
      }
    }
  }
}
