$primary: #FF5F15;
$light: #F0F1F7;
$white: white;
$grey: #6C6C6C;
$green: #41AD49;
$disabled-btn: $grey;
$black: #1D1D1F;
$danger: #EE1717;
$main: #f9fafc;
$blue: #819BEA;
$new-gray:#E2E2EA;