@import "src/styles/colors";
.sortContainer{
  display: flex;
  justify-content: flex-end;
}

.piesContainer {
  display: grid;
  grid-template-columns: 0.34fr 0.34fr 0.34fr;
  grid-gap: 13px;
  max-width: 100%;
  margin-bottom: 41px;

  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
.desktop {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.mobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.statContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px 53px;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 50px;
  @media screen and (max-width: 1268px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 376px) {
    grid-template-columns: 1fr;
  }
}

.statItem {
  width: 100%;
}

.activeIcon {
  color: $primary;

  path {
    fill: $primary !important;
  }
}

.managementIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  path {
    fill: black;
  }
}

.managementIcon + .managementIcon {
  margin-left: 10px;
}

.deleteIcon {
  cursor: pointer;
  margin-left: 10px;
  color: black;
  width: 24px;
  height: 24px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}
.button {
  width: 100%;
}
.cancel {
  border: 2px solid $primary;
}

.deleteBtn {
  width: 100%;
  margin-left: 10px;
}

.loader {
  height: 40%;
  margin-top: 40px;
}

.budgetItem {
  white-space: nowrap;
  width: calc(100% / 4);
  text-align: center;
}

.budgetItem + .budgetItem {
  margin-left: 27px;
}

.pauseHeader {
}
