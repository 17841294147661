@import "src/styles/colors";

.container {
    max-width: 600px;
    height: 100%;
    width: 100%;
}

.passContainer {
    position: absolute;
}

.title {
    font-family: "Mont Blanc", sans-serif;
    font-weight: 700 !important;
    font-size: 30px !important;
    color: $black;
    padding-top: 0 !important;
    line-height: 40px;
    margin-bottom: 60px;
    position: relative;

    @media screen and (max-width: 768px) {
        font-size: 25px !important;
        line-height: 30px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }
}

.location {
    margin-bottom: 40px;
    width: 100%;
}

.city {
    margin-left: 12px;
    width: 100%;
}

.sex {
    margin-bottom: 40px;
}

.centerRow {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
}

.smallText {
    font-size: 15px;
    color: $grey;
    line-height: 20px;
}

.sign {
    cursor: pointer;
    margin-left: 25px;
    color: $primary;
}

.status {
    font-size: 15px;
    color: red;
    margin: auto 0 23px;
    padding-top: 23px;
    font-weight: 500;
}

.backBtn {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    left: -40px;
    width: 40px !important;
    height: 40px !important;
    color: $black;

    @media screen and (max-width: 768px) {
        position: static;
        transform: translateX(-6px);
    }
}

.privacy {
    color: $primary;
}

.privacyText {
    margin-top: 20px;
    height: 400px;
    overflow: auto;
}

.privacyBtn {
    width: unset;
    margin: 10px auto 0;
}

.activeTab {
    border: 2px solid $primary;
    color: $black;
}

.tabBtn {
    margin-left: 20px;
}

.btnSize {
    font-size: 14px !important;
}

.modal_language {
    display: flex;
    justify-content: flex-end;

    span {
        margin: 0 5px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
}

.active_language {
    font-weight: 700;
    color: var(--primary-color);
}