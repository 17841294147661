@import "src/styles/colors";
.container{
  padding-top: 0;
}
.titleRow {
  margin-bottom: 14px;
  margin-left: 2px;
  display: flex;
  align-items: center;
}
.arrow {
  cursor: pointer;
  color: $primary;
  width: 24px;
  height: 24px;
  margin-top: 4px;
  margin-right: 10px;
}
.warning{
  font-size: 17px;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}
