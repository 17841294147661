.container {
  margin-bottom: 4px;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.label {
  width: 100%;
  font-family: "Mont Blanc", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 18px;
  margin-bottom: 4px;
}

.month {
  flex-grow: 2;
  margin: 0 1rem;
}

.day {
  flex-grow: 1;
}

.year {
  flex-grow: 1;
}

.error {
  color: #ee1717;
  margin-top: 3px;
  font-size: 12px;
  font-weight: 500;
}
