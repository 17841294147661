.dialog-upload-header {
  max-width: 500px;
  font-family: "Inter", sans-serif !important;

  .modal-header {
    display: none;
  }
}

.header-input {
  display: none;
}

.header-label {
  min-height: 140px;
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  svg {
    color: var(--primary-color);
  }
}
