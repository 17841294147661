.firstRow {
  align-items: center;
  flex-direction: column;

  .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgb(216, 216, 216);
    }
  }
}

.desktop-auction {
  @media screen and (max-width: 575px) {
    display: none;
  }
}
.mobile-auction {
  @media screen and (min-width: 575px) {
    display: none;
  }
}
