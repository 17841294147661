@import "src/styles/colors";

.form {
  &__forgot {
    color: #9596a2;
    font-size: 13px;
  }
  &__documentsLabel {
    line-height: 1.1 !important;
  }
}

.back {
  margin-left: -40px !important;
  display: flex;
  align-items: flex-start;

  &__button {
    margin-top: 2px !important;
  }

  @media (max-width: 460px) {
    margin-left: -20px !important;
  }
}
