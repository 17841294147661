.info-icon {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.icon--mask {
    mask-image: url("./images/info-icon.svg");
  }

  &.icon--background {
    background-image: url("./images/info-icon.svg");
  }
}
