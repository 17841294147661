@import "src/styles/colors";

.auction-calendar-content {
  .rdrCalendarWrapper {
    color: $black;
    font-size: 12px;
    font-family: "Mont Blanc", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    border-radius: 10px;
    display: flex;
  }

  .rdrDayNumber {
    color: $black !important;
  }
  .rdrSelected,
  .rdrDay,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    color: $primary !important;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: $primary;
  }
}
.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  content: "";
  border: 1px solid $primary !important;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.calendar-popover {
  .MuiPopover-paper {
    background: transparent !important;
    box-shadow: none !important;
    margin-top: 20px !important;
  }

  @media (max-width: 768px) {
    background: #00000050 !important;

    .MuiPopover-paper {
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      left: 50% !important;
    }
  }
}

.calendar-container {
  .text-input__right-addons {
    padding-right: 5px !important;
  }

  .input-arrow-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: hsl(0, 0%, 80%);
    }
    &__active {
      svg {
        color: hsl(0, 0%, 40%);
      }
    }
    &:hover {
      svg {
        color: hsl(0, 0%, 60%);
      }
    }
  }
}
