@import "src/theme";

.scroll-content-form {
  max-height: 60vh;
  width: 100%;
  padding-left: 10px;
}

.video-step-form {
  &__content {
    position: relative;
    padding: 0 1rem;

    .overlay {
      background-color: transparent !important;
    }

    @media screen and (max-width: 600px) {
      padding: 0;
    }
  }

  &__steps {
    margin: 45px auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $color-white;

    & + & {
      margin-left: 51px;
    }

    &--active {
      background-color: $text-primary;
    }
  }
}

@media (max-width: 420px) {
  .container-buttons-nav {
    flex-direction: column !important;

    div {
      max-width: 100% !important;
    }
  }
}

.buttons-selected-review {
  @media (max-width: 1200px) {
    button {
      padding: 0 15px !important;
    }
  }
}

.estimated-wrapper {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  div {
    border-radius: 8px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1d1d1f;
    font-weight: 400;
    flex-direction: column;
    padding: 10px 5px;
    text-align: center;

    p {
      color: #9596a2;
      font-size: 14px;
      font-weight: 300;
    }

    span {
      font-size: 32px;
      display: flex;
      align-items: center;
    }
  }
}
.video_checkbox_gender {
  .checkbox__box {
    width: 30px;
    height: 30px;
  }
}

.video-right-content {
  @media screen and (max-width: 900px) {
    display: none !important;
  }
}

.gender-item {
  @media screen and (max-width: 400px) {
    flex-direction: column !important;
  }
}

.video-message-error {
  font-size: 12px;
  color: red;
  font-weight: 500;
  line-height: 1.66;
  padding-top: 4px;
  margin-left: 4px;
}
