@import "src/styles/colors";

.container {
  max-width: 360px !important;
}

.content {
  * {
    font-family: "Inter", sans-serif !important;
  }

  .buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    &__cancel,
    &__delete {
      width: 100%;

      button {
        padding: 10px 16px !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        height: fit-content !important;
      }
    }
    &__cancel {
      margin-right: 0.5rem !important;
    }

    &__delete {
      margin-left: 0.5rem !important;
    }
  }
}
