@import "src/theme";


.popper {
    z-index: 10000;
}

.popper-content {
    background-color: #FFFFFF !important;

    &--dark {
        background-color: #231f20 !important;
    }
}