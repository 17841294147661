@import "src/styles/colors";


.modalDialog {
  @media (min-width: 576px) and(max-width: 991px) {
    max-width: 90%;
  }
}

.modalDialog {
  @media (min-width: 576px) and(max-width: 991px) {
    max-width: 90%;
  }
}

.modalHeader {
  font-weight: 600;
  font-size: 20px;
  line-height: 48px;
  padding: 10px;
  padding-left: 3rem;

  @media screen and (max-width: 600px) {
    padding-left: 2rem;
  }

  @media screen and (max-width: 1360px) {
    font-size: 18px;
    line-height: 40px;
  }

  .grid-item-nav {
    width: 100%;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    display: block;
    .buttonContainer {
      margin-top: 1rem;
    }
  }
}
