.phone-input-container {
  display: flex;
  height: 50px;
  border-radius: 10px;
  background-color: #ffffff;
  align-items: center;
  position: relative;

  &:focus-within {
    box-shadow: rgb(255 95 21 / 50%) 0px 0px 0px 0.2rem;
  }

  .phone-select {
    width: auto;
    position: static;
    .select {
      &__control {
        &:focus-within {
          box-shadow: none !important;
          z-index: 1;
        }
      }
      &__value-container {
        padding: 2px 0 2px 1rem !important;
      }

      &__single-value {
        width: 32px;
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 2px;
        }
      }
      &__option {
        display: flex;
        align-items: center;
        img {
          margin-right: 1rem;
          width: 38px;
          border-radius: 2px;
          object-fit: cover;
        }
      }
    }
  }

  &__input {
    width: 100%;
    border: none;
    font-size: 14px;
    padding-left: 1rem;
  }
}

.phone-input-error {
  color: #ee1717;
  margin-top: 3px;
  font-size: 12px;
  font-weight: 500;
}
