.go2441762052 {
    text-align: center;
    position: absolute;
    bottom: 70px;
    left: 55%;
}

.go2791332690 {
    border-bottom: none !important;
}

.go2791332690 span {
    display: none !important;
}

/* .go3842760039:before,  */
.go2335061104:before {
    border-radius: 50% !important;
    background-color: #707070 !important;
    content: "" !important;
    width: 10px !important;
    height: 10px !important;
}

.go433304200:before,
.go3842760039:before {
    content: "" !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    background-color: #000 !important;
}

/* .multistep-container > div> div:last-child > div:first-child {
    visibility: hidden !important;
    position: relative !important;
}
.multistep-container > div> div:last-child > div:first-child:after {
    visibility: visible;
    position: absolute;
   top: 0;
    left: 0; 
     content: "Далее";
}  */



/* .multistep-container > div> div:last-child > div:first-child:after {

    content: 'Дальше';
    background : #000;
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;

}

.multistep-container > div> div:last-child > div:first-child >button {
    color: rgb(241, 241, 241) !important;
} */
/* button {
    background: #000 !important;
    padding: 5px 10px !important;
    border-radius: 50px !important;
    color: #fff !important;
}
button > * {
    color: #fff !important;
} */
.btn-black-outline {
    border: 2px solid #000;
    border-radius: 20px;
    padding: 5px 50px;
    background: none;

}

.upload__image-wrapper {
    padding: 21px !important;
}

.upload__image-wrapper-button {
    padding: 100px;
}

.modal-content {
    width: 100% !important;
    border-radius: 20px !important;
}

.css-1z7n62 {
    display: none !important;
}

.btn-black {
    background: #000 !important;
    border-radius: 20px !important;
    padding: 5px 10px !important;
    color: #fff !important;
    text-transform: none !important;
    /* width: 50% !important; */
}

.w-50 {
    width: 50% !important;
}

.w-small {
    width: 20% !important;
}

.btn-orange-outline {
    background: none;
    border: 2px solid #FA8200;
    border-radius: 20px;
    padding: 5px 20px;
    width: 100%;
}

/* .link-btn:after {
   content : '../../assets/';
} */
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 20px !important;
    padding: 16.5px 33px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
}

@media screen and (max-width: 768px) {
    .upload__image-wrapper-button {
        padding: 15px;
    }
}

/* .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
} */