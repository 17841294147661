@import "src/theme";

.checkbox {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  cursor: pointer;

  &__input {
    display: none;
  }

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background-color: #ffffff;
  }

  &__box--xl {
    width: 35px;
    height: 35px;
    border-radius: 10px;
  }

  &__box--xs {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }

  &--variant-grey &__box--xl, &__box--xs {
    background-color: #f0f1f7;
  }

  &--error &__box {
    border-color: red;
  }

  &--checked &__box {
    background-color: $color-primary;
    background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0)
    );
  }

  &__icon {
    display: none;
  }

  &--checked &__icon {
    display: block;
  }

  &__label {
    margin-left: 8px;
    color: rgb(29, 29, 31);
    font-family: "Mont Blanc", sans-serif;
    font-size: 16px;
    user-select: none;
  }
}
