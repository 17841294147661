@import "src/styles/colors";
.container {
  width: fit-content;
  padding: 4px;
  border-radius: 10px;
  background-color: $new-gray;
  display: flex;
  align-items: center;
  position: relative;
}

.option{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 34px;
  cursor: pointer;
  height: 100%;
  white-space: nowrap;
  z-index: 2;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: $black;
  transition: color ease-in-out 0.3s;
}

.activeOption{
  color: white;
}

.activePoint{
  z-index: 1;
  background-color: $primary;
  border-radius: 9px;
  width: 50%;
  position: absolute;
  bottom: 2px;
  top: 2px;
  transition: all ease-in-out 0.3s;
}

.leftActive{
  left: 2px;
}