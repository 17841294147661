.container {
  * {
    font-family: "Inter", sans-serif !important;
  }
}

.title {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 600;
}
