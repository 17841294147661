@import "src/styles/colors";

.container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  padding: 10px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  @media screen and (min-width: 481px) {
    display: none;
  }
}

.burger {
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  color: white;
}

.menu {
  position: absolute;
  top: 0;
  right: 100%;
  transition: all ease-in-out 0.3s;
  width: 100vw;
  height: 100vh;
  background-color: #1d1d1f;
}

.openMenu {
  right: 0;
}

.user {
  position: absolute !important;
  right: 10px;
  width: 40px;
  height: 40px;
}