.scroll-bar {
    position: relative;

    &.ps--active-y {
        padding-right: 20px;

        .ps__rail-y {
            display: block;
            right: 0 !important;
            background-color: transparent;
            width: 5px;
            padding: 0 !important;
        }
    }
}
