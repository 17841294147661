.container {
  display: flex;
  flex-direction: column;
}
.videoContainer {
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.strangerLot {
  background-color: #d7d7d7;
}
.videoContainer + .videoContainer {
  margin-top: 20px;
}
.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  margin-bottom: 10px;
}
.description {
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
}
.actions {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}
.propertyWithDesc {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
}
.action {
  color: black;
  margin: 0 5px;
  width: 40px !important;
  height: unset !important;
}
