@import "src/theme";


.data-table-head {
    // padding-bottom: 15px;
    //margin-bottom: 15px;
    transform: translateY(12px);
    position: relative;
    z-index: 1;
    &__row {
        //margin-bottom: 15px;
        th:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        th:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    &__cell {
        background-color: #e2e2ea;
        white-space: nowrap;
        padding: 16px 0 13px !important;
        color: #9596a2 !important;
        font-size: 12px;
    }
}
