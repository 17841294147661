.modal-wrapper {
    overflow-y: auto;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #231f2099;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1000;
}