@import "src/styles/colors";

.container {
  //width: 100%;
}

.disabled {
  background-color: $disabled-btn !important;
  color: $white !important;
  cursor: default !important;
}

.button {
  font-family: "Mont Blanc", sans-serif;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  height: 50px;
  cursor: pointer;
  padding: 10px 16px 12px;
  border-radius: 10px;
  transition: opacity 0.1s linear;

  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: 1360px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1240px) {
    font-size: 14px;
  }
}

.size-small {
  padding: 5px 8px 6px !important;
  height: 34px;

  font-size: 16px;

  @media screen and (max-width: 1360px) {
    font-size: 14x;
  }

  @media screen and (max-width: 1240px) {
    font-size: 14px;
  }
}

.variant-primary {
  background-color: $primary;
  color: $white;
}

.variant-white {
  background-color: $white;
  color: $primary;
}

.variant-black {
  background: black;
  color: white;
}

.variant-delete {
  background-color: $danger;
  color: $white;
}

.variant-inline {
  width: fit-content;
  color: var(--primary-color);
  cursor: pointer;
}

.variant-gray {
  background: #e3e3e3;
  color: black;
  cursor: pointer;
}

.variant-light {
  background: var(--primary-color);
  font-size: 16px;
  width: fit-content;
  color: white;
}

.variant-outline {
  font-size: 16px;
  height: 0;
  width: fit-content;
  padding: 0;
  background-color: inherit;
  color: $primary;
  padding-bottom: 35px;

  &:disabled {
    color: gray !important;
    cursor: default !important;
    background-color: inherit !important;
  }
}

.variant-outlined {
  color: $primary;

  border: 1px solid $primary;

  &:disabled {
    color: gray !important;
    cursor: default !important;
    background-color: inherit !important;
  }
}
