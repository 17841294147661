@import "src/theme";


.input-group {
    display: inline-flex;
    flex-direction: column;

    &--full-width {
        width: 100%;
    }

    &__label {
        font-family: "Mont Blanc", sans-serif;
        color: #1D1D1F;
        font-size: 20px;
        font-weight: 600;
        padding-left: 18px;
        padding-bottom: 4px;

        @include down("lg") {
            font-size: 16px;
        }

        @include down("md") {
            font-size: 14px;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
    }

    &__divider {
        border-right: solid #707070 4px;
        height: 100%;
    }
}