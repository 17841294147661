.multi-select-open {
  box-shadow: rgb(255 95 21 / 50%) 0 0 0 0.2rem !important;
}

.multy-select {
  .auto-select__angle {
    transform: rotate(0deg);
  }

  &--open {
    .auto-select__angle {
      transform: rotate(0deg);
    }
  }

  border-radius: 10px;
  min-height: 50px;
  display: flex;
  align-items: center;

  &__clear {
    display: none;
  }

  &--filled &__clear {
    display: block;
  }

  .MuiAutocomplete-popupIndicator {
    &:hover {
      background-color: transparent !important;
    }
  }

  .MuiAutocomplete-endAdornment {
    right: 14px !important;
  }

  .MuiInputBase-input {
    font-size: 14px !important;
    padding: 2px 4px 2px 6px !important;
    font-weight: 500 !important;
  }
}

.multy-select-paper {
  margin-top: -10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.MuiOutlinedInput-notchedOutline {
  display: none !important;
}

.color-white {
  background-color: white;
}

.MuiOutlinedInput-root {
  padding: 6px;
}

.color-gray {
  background-color: #f0f1f7;
}

.MuiAutocomplete {
  outline: none !important;
  -webkit-box-shadow: 0 13px 34px -12px rgba(0, 0, 0, 0.23) !important;
  box-shadow: 0 13px 34px -12px rgba(0, 0, 0, 0.23) !important;
  border-radius: 10px !important;
  border: none !important;
  margin-top: -12px !important;
  padding: 23px 9px 9px 11px;
  font-size: 18px;
}
