@import "src/theme";


.text-input {
    display: inline-flex;
    flex-direction: column;
    // margin-top: 15px !important;

    &--full-width {
        width: 100%;
    }


    &__label {
        font-family: "Mont Blanc", sans-serif;
        color: #1D1D1F;
        font-size: 20px;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 4px;

        &--warrning {
            font-family: "Mont Blanc", sans-serif;
            color: #1D1D1F;
            font-size: 20px;
            font-weight: 600;
            margin-left: 10px;
            margin-bottom: 4px;

            &::after {
                content: '*';
                margin-left: 4px;
                color: red;
                font-size: 20px;
            }
        }

        @include down("lg") {
            font-size: 16px;
        }

        @include down("md") {
            font-size: 14px;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 50px;
        border-radius: 10px;
        background-color: #FFFFFF;



    }

    .input-group__wrapper>&:not(:last-child) &__wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group__wrapper>&:not(:first-child) &__wrapper {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &--variant-grey &__wrapper {
        background-color: #f0f1f7;
    }


    &__input {
        //flex: 1;
        width: 100%;
        min-width: 0;
        font-size: 14px;
        padding: 11px 5px;
        border: none;


        &:hover {
            .text-input__wrapper {
                box-shadow: rgba(255, 95, 21, 0.5) 0 0 0 0.2rem !important;
                border: none !important;
                outline: none !important;
            }
        }

        &:active {
            outline: none;
            -webkit-appearance: none;
        }


        &:invalid {
            box-shadow: none;
            -webkit-box-shadow: none;
        }

        &::-webkit-search-decoration {
            -webkit-appearance: none;
        }

        &::-webkit-search-cancel-button {
            display: none;
        }

        &::-webkit-input-placeholder {
            text-indent: 0;
        }

        &::-moz-placeholder {
            opacity: 1;
        }

        &::-ms-clear {
            display: none;
        }

        &::placeholder {
            color: $color-muted;
        }
    }

    &__left-addons,
    &__right-addons {
        position: relative;
        display: flex;
        flex: 0;
        align-items: center;
        justify-content: center;
    }

    &__left-addons {
        padding-left: 14px;
    }

    &__right-addons {
        padding-right: 14px;
    }

    &__helper-text {
        margin-top: 3px;
        font-size: 12px;
        font-weight: 500;
    }

    &--error &__helper-text {
        color: $text-error;
    }
}