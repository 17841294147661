.support {
  * {
    font-family: "Inter", sans-serif !important;
  }
}

.button {
  padding: 10px 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: fit-content !important;
  white-space: nowrap;
}
