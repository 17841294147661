$text-primary: #FF5F00;
$text-error: #EE1717;
$text-muted: #AAAAAA;

$color-primary: $text-primary;
$color-warning: #FFC107;
$color-error: $text-error;
$color-black: #1D1D1F;
$color-white: #FFFFFF;
$color-grey: #6C6C6C;
$color-muted: $text-muted;

$bg-dark: #231f20;


:export {
    textPrimary: $text-primary;
    textError: $text-error;
    colorBlack:$color-black
}
