@import "src/theme";

.profile-container {
    position: relative;
}

.profile-avatar {
    cursor: default !important;
    color: $text-primary;
    background-color: $color-white;
}


.setting-label {
    position: absolute;
    top: -9px;
    right: -4px;

    svg {
        background-color: var(--primary-color);
        border-radius: 50%;
        padding: 3px;
        fill: white;
    }
}