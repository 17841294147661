.focus-black:focus {
  background-color: black;
  color: #fff;
}

.focus-black {
  padding: 10px;
  border-radius: 20px;
}

.back-white {
  background: #fff;
  border-radius: 20px;
  padding: 10px;
  overflow: hidden;
}

.orange {
  color: #fa8200;
  font-weight: bold;
}

.recharts-wrapper {
  /* height: 300px !important; */
  /* width: 300px !important; */
}

.dashboard-container {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  display: flex;
  flex-direction: column;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 595px) {
      flex-direction: column;
      align-items: flex-start;
      & > div {
        align-self: center;
        margin-top: 10px;
      }
    }
  }
}

.Component-root-1 {
  height: 200px !important;
  padding: 0 !important;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Component-root-8 {
  height: 300px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-red {
  background: red;
}

.status-yellow {
  background: yellow;
}

.status-green {
  background: green;
}

.focus-black:focus {
  background: black !important;
  color: #fff !important;
  border-radius: 20px !important;
  padding: 8px !important;
}
